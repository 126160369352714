import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import {
  BreadcrumbComponent,
  type BreadcrumbItem,
} from '@/components/breadCrumb/BreadcrumbComponent';
import { PageTitle } from '@/components/pageTitle/PageTitle';
import { threadsRoute } from '@/routes/threadsRoute';

import { UserThreadsTable } from './userThreadsTable/userThreadsTable';

export function ChatBotManagementThreadsPage() {
  const { t } = useTranslation(['threads', 'common']);
  const { userId } = threadsRoute.useParams();
  const managementThreadsLinks: BreadcrumbItem[] = [
    { label: t('common:titles.messageManagement'), href: '/messages' },
    { label: t('common:titles.threads'), href: `/messages/threads/${userId}` },
  ];

  return (
    <div className='flex flex-col gap-3 '>
      <BreadcrumbComponent items={managementThreadsLinks} />

      <div className='flex flex-row'>
        <Link
          to={`/messages/threads/${userId}`}
          activeProps={{
            className: 'border-b-teal-500 font-bold text-teal-500',
          }}
          className='flex border-b-2 border-b-gray-200 bg-gray-50 px-10 py-4'
        >
          {t('threads:tabs.messages')}
        </Link>
        <Link
          to={`/messages/threads/${userId}/feedback`}
          className='flex border-b-2 border-b-gray-200 bg-gray-50 px-10 py-4'
        >
          {t('threads:tabs.feedbacks')}
        </Link>
      </div>
      <PageTitle title={t('common:titles.threads')} />
      <UserThreadsTable />
    </div>
  );
}
