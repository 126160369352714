import { Link } from '@tanstack/react-router';
import { Button } from '@utima/ui';
import { Mail } from 'lucide-react';
import { Trans, useTranslation } from 'react-i18next';

type ForgottenPasswordSentPageProps = {
  email?: string;
};

export function ForgottenPasswordSentPage({
  email,
}: ForgottenPasswordSentPageProps) {
  const { t } = useTranslation('auth');

  return (
    <div className='flex flex-col items-center gap-6'>
      <h1 className='text-center text-2xl font-bold'>
        {t('forgottenPasswordSent.title')}
      </h1>
      <div className='rounded bg-primary/10 px-4 py-3'>
        <Mail className='size-[42px] text-primary' />
      </div>
      <div className='px-10 text-center text-sm'>
        <Trans
          // @ts-expect-error because Trans component doesn't look into namespaces for keys
          i18nKey='auth:forgottenPasswordSent.subtitle'
          values={{ email }}
        />
      </div>
      <Link to='/login' className='w-full'>
        <Button size='lg'>{t('forgottenPasswordSent.toLogin')}</Button>
      </Link>
    </div>
  );
}
