import { Button, Dialog, Separator } from '@utima/ui';
import { useContext, useState } from 'react';

import { DialogContext } from './DialogContext';

export function FFDialog() {
  const {
    dialogProps: {
      children,
      title,
      subtitle,
      separatorClassName,
      contentClassName,
      onConfirm,
      onCancel,
      confirmText,
      cancelText,
    },
    open,
    setOpen,
  } = useContext(DialogContext);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const handleConfirm = async () => {
    setConfirmLoading(true);
    await onConfirm?.();
    setConfirmLoading(false);
    setOpen(false);
  };

  const handleCancel = async () => {
    setCancelLoading(true);
    await onCancel?.();
    setCancelLoading(false);
    setOpen(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content
        onPointerDownOutside={event => event.preventDefault()}
        className={contentClassName}
      >
        <Dialog.Header>
          {title && <Dialog.Title>{title}</Dialog.Title>}
          {separatorClassName && (
            <Separator size='lg' className={separatorClassName} />
          )}
          {subtitle && <Dialog.Description>{subtitle}</Dialog.Description>}
        </Dialog.Header>
        {children}
        {(cancelText || confirmText) && (
          <Dialog.Footer>
            {cancelText && (
              <Button
                loading={cancelLoading}
                disabled={cancelLoading || confirmLoading}
                onClick={handleCancel}
                variant='secondary'
              >
                {cancelText}
              </Button>
            )}
            {confirmText && (
              <Button
                loading={confirmLoading}
                disabled={cancelLoading || confirmLoading}
                onClick={handleConfirm}
                variant='primary'
              >
                {confirmText}
              </Button>
            )}
          </Dialog.Footer>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
}
