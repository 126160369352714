import { relations, type InferSelectModel } from 'drizzle-orm';
import { pgTable, timestamp, uuid, varchar } from 'drizzle-orm/pg-core';

import { userTable, type User } from '../schema';

export const refreshTokenTable = pgTable('refresh_token', {
  token: varchar('token').primaryKey().notNull(),
  user_id: uuid('user_id')
    .notNull()
    .references(() => userTable.id),
  created_at: timestamp('created_at', { withTimezone: true, mode: 'string' })
    .defaultNow()
    .notNull(),
  expire_at: timestamp('expire_at', {
    withTimezone: true,
    mode: 'date',
  }),
});

export const refreshTokenUserRelations = relations(
  refreshTokenTable,
  ({ one }) => ({
    user: one(userTable, {
      fields: [refreshTokenTable.user_id],
      references: [userTable.id],
    }),
  }),
);

export type RefreshToken = InferSelectModel<typeof refreshTokenTable>;
export interface RefreshTokenWithRelations extends RefreshToken {
  user?: User;
}
