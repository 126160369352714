import { useSearch } from '@tanstack/react-router';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
  type RowData,
  type PaginationState,
} from '@tanstack/react-table';
import type { UseTRPCQueryResult } from '@trpc/react-query/shared';
import { Table } from '@utima/ui';

import { Pagination } from '../pagination/Pagination';

// Used instead of inline [] to prevent unnecessary re-renders
const DEFAULT_DATA: never[] = [];

/**
 * Extend tanstack query meta types
 */
declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    className?: string;
  }
}

interface DataTableProps<TData> {
  //this is not what columnHelper is really returning, more in https://github.com/TanStack/table/issues/4382
  columns: ColumnDef<TData, any>[];
  useQuery: ({
    pageSize,
    pageIndex,
  }: {
    pageSize: number;
    pageIndex: number;
  }) => UseTRPCQueryResult<{ items: TData[]; meta: any }, any>;
}

export function DataTable<TData>({ columns, useQuery }: DataTableProps<TData>) {
  const { page = 1, pageSize = 10 } = useSearch({
    strict: false,
  });

  const pagination: PaginationState = {
    pageIndex: page - 1,
    pageSize: pageSize,
  };

  /**
   * Handle data fetching using on onQuery request handler
   * and react-query.
   */
  const { data } = useQuery({
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
  });

  const table = useReactTable({
    data: data?.items ?? DEFAULT_DATA,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: data?.meta.pageCount ?? 0,
    state: {
      pagination: pagination,
    },
  });

  return (
    <div className='rounded-md border'>
      <Table.Root>
        <Table.Head>
          {table.getHeaderGroups().map(headerGroup => (
            <Table.HRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const { columnDef } = header.column;
                const { className } = columnDef.meta ?? {};

                return (
                  <Table.HCol key={header.id} className={className}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </Table.HCol>
                );
              })}
            </Table.HRow>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map(row => (
              <Table.Row
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map(cell => {
                  const { className } = cell.column.columnDef.meta ?? {};

                  return (
                    <Table.Col key={cell.id} className={className}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Table.Col>
                  );
                })}
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Col colSpan={columns.length} className='h-24 text-center'>
                No results.
              </Table.Col>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
      <Pagination table={table} />
    </div>
  );
}
