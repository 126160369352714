import { createRoute } from '@tanstack/react-router';

import { UsersPage } from '@/pages/users/UsersPage';

import { adminLayoutRoute } from './adminLayoutRoute';

export const usersRoute = createRoute({
  getParentRoute: () => adminLayoutRoute,
  path: '/users',
  component: UsersPage,
});
