import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DialogContext,
  type DialogProps,
} from '@/components/dialogContext/DialogContext';
import { useDialog } from '@/components/dialogContext/useDialog';

import { UserForm } from '../userForm/UserForm';

export function useCreateUserDialog() {
  const { t } = useTranslation('users');

  const { setOpen } = useContext(DialogContext);
  const closeDialog = () => setOpen(false);

  const addUserDialogProps = {
    title: t('addUser'),
    separatorClassName: 'w-24',
    contentClassName: 'max-w-xl',
    children: (
      <UserForm onCancel={closeDialog} onSubmit={closeDialog} profile={false} />
    ),
  } as DialogProps;
  const { openDialog } = useDialog(addUserDialogProps);

  return { openDialog };
}
