import { createContext, useMemo, useState, type ReactNode } from 'react';

import { FFDialog } from './FFDialog';

export type DialogProps = {
  children?: ReactNode;
  title?: string;
  subtitle?: string | ReactNode;
  separatorClassName?: string;
  contentClassName?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void | Promise<unknown>;
  onCancel?: () => void | Promise<unknown>;
};

export type DialogContextType = {
  dialogProps: DialogProps;
  setDialogProps: (props: DialogProps) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const DialogContext = createContext<DialogContextType>(
  undefined! as DialogContextType,
);

type DialogProviderProps = {
  children: ReactNode;
};

export function DialogProvider({ children }: DialogProviderProps) {
  const [open, setOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<DialogProps>({});

  const value = useMemo(() => {
    return { dialogProps, setDialogProps, open, setOpen };
  }, [dialogProps, open]);

  return (
    <DialogContext.Provider value={value}>
      <FFDialog />
      {children}
    </DialogContext.Provider>
  );
}
