import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

interface FeedbackTabsProps {
  userId: string;
}

export function FeedbackTabs({ userId }: FeedbackTabsProps) {
  const { t } = useTranslation(['feedbacks', 'common']);

  return (
    <div className='flex flex-row'>
      <Link
        to={`/messages/threads/${userId}`}
        className='flex border-b-2 border-b-gray-200 bg-gray-50 px-10 py-4'
      >
        {t('feedbacks:tabs.messages')}
      </Link>
      <Link
        to={`/messages/threads/${userId}/feedback`}
        activeProps={{
          className: 'border-b-teal-500 font-bold text-teal-500',
        }}
        className='flex border-b-2 border-b-gray-200 bg-gray-50 px-10 py-4'
      >
        {t('feedbacks:tabs.feedbacks')}
      </Link>
    </div>
  );
}
