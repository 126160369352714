import { DataTable } from '@/components/dataTable/DataTable';
import { trpc } from '@/services/trpc';

import { useOperatorCols } from '../hooks/useOperatorCols';

export function OperatorsTable() {
  const columns = useOperatorCols();

  const useQuery = ({
    pageSize,
    pageIndex,
  }: {
    pageSize: number;
    pageIndex: number;
  }) =>
    trpc.operator.paginatedOperators.useQuery({
      pageSize: pageSize,
      pageIndex: pageIndex,
    });

  return <DataTable columns={columns} useQuery={useQuery} />;
}
