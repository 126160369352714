import type { AppRouter } from '@form-factory-ai/admin-api/src/routers';
import { refreshTokenLink } from '@pyncz/trpc-refresh-token-link';
import type { TRPCLink } from '@trpc/client';
import {
  type CreateTRPCReact,
  createTRPCReact,
  httpBatchLink,
  createTRPCClient,
} from '@trpc/react-query';

import { AppSettings } from '@/constants/AppSettings';

import { useMyStore } from '../store/useMyStore';

export const trpc: CreateTRPCReact<AppRouter, unknown> =
  createTRPCReact<AppRouter>();

const apiUrl = AppSettings.apiUrl;

// refreshing token needs to be done with a different client without the refresh token link
export const trpcWithoutRefreshLink = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      url: apiUrl + 'trpc',
      headers() {
        return {
          AccessControlAllowCredentials: 'true',
        };
      },
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: 'include',
        });
      },
    }),
  ],
});

export const trpcClient = trpc.createClient({
  links: [
    refreshTokenLink({
      getRefreshToken: () => {
        // our token is stored in httpsOnly cookie, but we use user email to verify the user
        return useMyStore.getState().user?.email;
      },
      fetchJwtPairByRefreshToken: refreshData => {
        return trpcWithoutRefreshLink.auth.refreshToken.query({
          email: refreshData,
        });
      },
      onJwtPairFetched: payload => {
        useMyStore.getState().setJwt(payload.access);
      },
      onRefreshFailed: () => {
        // if the refresh token is invalid, we should log out the user
        useMyStore.getState().logout();
      },
    }) as TRPCLink<AppRouter>, //this is safe only because we don't use transformer, more here https://trpc.io/docs/migrate-from-v10-to-v11#transformers-are-moved-to-links-breaking
    httpBatchLink({
      url: apiUrl + 'trpc',
      headers() {
        return {
          ...(useMyStore.getState().jwt
            ? { Authorization: 'Bearer ' + useMyStore.getState().jwt }
            : {}),
        };
      },
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: 'include',
        });
      },
      transformer: undefined,
    }),
  ],
});
