import type { InferSelectModel } from 'drizzle-orm';
import { pgTable, timestamp, uuid, varchar } from 'drizzle-orm/pg-core';

export const operatorTable = pgTable('operators', {
  id: uuid('id')
    .primaryKey()
    .notNull()
    .$defaultFn(() => crypto.randomUUID()),
  branch: varchar('branch', { length: 40 }).notNull(),
  city: varchar('city', { length: 40 }).notNull(),
  name: varchar('name', { length: 100 }).notNull(),
  phone: varchar('phone', { length: 20 }).notNull(),
  email: varchar('email', { length: 80 }).notNull(),
  created_at: timestamp('created_at', { withTimezone: true, mode: 'string' })
    .defaultNow()
    .notNull(),
  updated_at: timestamp('updated_at', {
    withTimezone: true,
    mode: 'string',
  }).$onUpdate(() => new Date().toISOString()),
});

export type Operator = InferSelectModel<typeof operatorTable>;
