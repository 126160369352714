import type { Operator } from '@form-factory-ai/admin-api/src/schema/schema';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionCellOperator } from '../operatorsTable/ActionCell';

const columnHelper = createColumnHelper<Operator>();

export function useOperatorCols() {
  const { t } = useTranslation('operators');

  return useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('tableHead.name'),
      }),
      columnHelper.accessor('email', {
        header: t('tableHead.email'),
      }),
      columnHelper.accessor('phone', {
        header: t('tableHead.phone'),
      }),
      columnHelper.accessor('branch', {
        header: t('tableHead.branch'),
      }),
      columnHelper.accessor('city', {
        header: t('tableHead.city'),
      }),
      columnHelper.display({
        id: 'actions',
        header: t('tableHead.actions'),
        cell: ({ row }) => <ActionCellOperator operatorData={row.original} />,
        meta: {
          className: 'w-52 text-center',
        },
      }),
    ],
    [t],
  );
}
