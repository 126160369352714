import {
  RoleEnum,
  createUserSchema,
  updateUserSchema,
  type UserCreateDTO,
  type User,
} from '@form-factory-ai/admin-api/src/schema/schema';
import { SelectItem, toast } from '@utima/ui';
import * as Form from '@utima/ui-informed';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormActions } from '@/components/formActions/FormActions';
import { UserProfilePhoto } from '@/components/userProfilePhoto/UserProfilePhoto';
import { trpc } from '@/services/trpc';
import { useMyStore } from '@/store/useMyStore';
import { translateRole } from '@/utils/utils';

type UserFormProps = {
  data?: User;
  onCancel?: () => void;
  onSubmit?: () => void;
  profile?: boolean;
};

export function UserForm({ data, onCancel, onSubmit, profile }: UserFormProps) {
  const { t } = useTranslation('users');
  const { t: tForm } = useTranslation('form');
  const [photo, setPhoto] = useState<string | null>(null);

  useEffect(() => {
    setPhoto(data?.photo_url || '');
  }, [data]);
  const handlePhotoChange = (base64String: string | null) => {
    setPhoto(base64String);
  };
  const utils = trpc.useUtils();
  const createUserMutation = trpc.user.createUser.useMutation();
  const updateUserMutation = profile
    ? trpc.user.updateProfile.useMutation()
    : trpc.user.updateUser.useMutation();

  const rolesQuery = trpc.role.getRoles.useQuery();
  const user = useMyStore(state => state.user);
  const isRoleReadOnly =
    user?.role !== RoleEnum.Admin || user.email === data?.email;

  const handleSubmit = async (
    formState: Form.TypedFormState<UserCreateDTO | undefined>,
  ) => {
    const { values } = formState;

    if (!values) {
      return;
    }
    const updatedValues = { ...values, photo_url: photo };
    try {
      await (data
        ? updateUserMutation.mutateAsync(
            {
              id: data.id,
              ...updatedValues,
            },
            {
              onSuccess: () => {
                if (user && user.email === data.email) {
                  utils.user.me.invalidate();
                }
                utils.user.paginatedUsers.invalidate();
              },
            },
          )
        : createUserMutation.mutateAsync(updatedValues, {
            onSuccess: () => {
              utils.user.paginatedUsers.invalidate();
            },
          }));

      toast.success(tForm('actions.success.title'), {
        description: tForm('actions.success.message'),
      });

      utils.user.getUsersWithRole.invalidate();

      onSubmit?.();
    } catch {
      toast.error(tForm('actions.fail.title'), {
        description: tForm('actions.fail.message'),
      });
    }
  };

  return (
    <Form.Form
      initialValues={data}
      onSubmit={handleSubmit}
      zodSchema={data ? updateUserSchema : createUserSchema}
      className='flex w-full flex-col gap-2.5 p-3'
    >
      <UserProfilePhoto
        title='Profile Photo'
        photo={photo}
        setPhoto={setPhoto}
        onPhotoChange={handlePhotoChange}
      />
      <Form.Input
        name='name'
        label={t('form.name')}
        placeholder={t('form.name')}
        required
      />
      <Form.Input
        name='surname'
        label={t('form.surname')}
        placeholder={t('form.surname')}
        required
      />
      <Form.Input
        name='email'
        label={t('form.email')}
        placeholder={t('form.email')}
        readOnly={!!data}
        required
      />
      <Form.Select
        name='role_id'
        label={t('form.role')}
        placeholder={t('form.role')}
        readOnly={isRoleReadOnly}
        required
      >
        {rolesQuery.data?.map(role => (
          <SelectItem key={role.id} value={role.id}>
            {translateRole(role.name, t)}
          </SelectItem>
        ))}
      </Form.Select>
      <FormActions onCancel={onCancel} />
    </Form.Form>
  );
}
