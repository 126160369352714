import { createRoute } from '@tanstack/react-router';

import { ForgottenPasswordSentPage } from '@/pages/forgottenPasswordSent/ForgottenPasswordSentPage.tsx';

import { forgottenPasswordRoot } from './forgottenPasswordRoute.tsx';

type ForgottenPasswordSentSearch = {
  email: string;
};

export const forgottenPasswordSentRoute = createRoute({
  getParentRoute: () => forgottenPasswordRoot,
  path: 'sent',
  component: ForgottenPasswordSentPageWrapper,
  validateSearch: (
    search: Record<string, unknown>,
  ): ForgottenPasswordSentSearch => {
    return {
      email: (search.email as string) || '',
    };
  },
});

function ForgottenPasswordSentPageWrapper() {
  const { email } = forgottenPasswordSentRoute.useSearch();

  return <ForgottenPasswordSentPage email={email} />;
}
