import { createRoute } from '@tanstack/react-router';

import { ProfilePage } from '@/pages/profile/ProfilePage';

import { adminLayoutRoute } from './adminLayoutRoute';

export const profileRoute = createRoute({
  getParentRoute: () => adminLayoutRoute,
  path: '/profile',
  component: ProfilePage,
});
