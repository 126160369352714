import { Badge } from '@utima/ui';
import { useTranslation } from 'react-i18next';

import { translateRole } from '@/utils/utils';

type RoleBadgeProps = {
  role: 'admin' | 'support';
};

export function RoleBadge({ role }: RoleBadgeProps) {
  const { t } = useTranslation('users');

  return role === 'admin' ? (
    <Badge variant='primary'>{translateRole(role, t)}</Badge>
  ) : (
    <Badge variant='secondary'>{translateRole(role, t)}</Badge>
  );
}
