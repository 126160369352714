import { Outlet } from '@tanstack/react-router';
import { Grip } from 'lucide-react';

import FFAuthLayoutImg from '@/assets/images/FF-auth-layout.jpeg';
import FFLogoImg from '@/assets/logos/FF-with-text.svg';

export function AuthLayout() {
  return (
    <div className='flex h-screen bg-background'>
      <div className='hidden h-full w-1/2 lg:block'>
        <img
          src={FFAuthLayoutImg}
          alt='Form Factory'
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </div>
      <div className='flex size-full items-center justify-center lg:w-1/2'>
        <div className='relative flex min-h-[618px] w-[520px] flex-col items-center rounded-lg bg-white p-16 shadow-lg'>
          <Grip size={48} className='absolute right-2 top-2 rotate-180' />
          <Grip size={48} className='absolute bottom-2 left-2' />
          <img src={FFLogoImg} alt='ff-logo' className=' mb-6' />
          <Outlet />
        </div>
      </div>
    </div>
  );
}
