import { createRoute } from '@tanstack/react-router';

import { ChatBotManagementFeedbackPage } from '@/pages/chatbotManagement/ChatbotManagementFeedbackPage';

import { adminLayoutRoute } from './adminLayoutRoute';

export const feedbackRoute = createRoute({
  getParentRoute: () => adminLayoutRoute,
  path: '/messages/threads/$userId/feedback',
  component: ChatBotManagementFeedbackPage,
});
