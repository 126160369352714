import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DialogContext,
  type DialogProps,
} from '@/components/dialogContext/DialogContext';
import { useDialog } from '@/components/dialogContext/useDialog';

import type { FeedbackType } from './useFeedbacksCol';
import { FeedbackForm } from '../chatbotManagementForm/FeedbackForm';

export function useReadFeedbackDialog(feedbackData: FeedbackType) {
  const { t } = useTranslation('feedbacks');

  const { setOpen } = useContext(DialogContext);
  const closeDialog = () => setOpen(false);

  const addFaqDialogProps: DialogProps = {
    title: t('feedbackDetail'),
    separatorClassName: 'w-24',
    contentClassName: 'max-w-2xl',
    children: <FeedbackForm onCancel={closeDialog} data={feedbackData} />,
  };
  const { openDialog } = useDialog(addFaqDialogProps);

  return { openDialog };
}
