import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import z from 'zod';

import { faqTable } from './faqSchema';

export const selectFaqSchema = createSelectSchema(faqTable, {});

export const findFaqsByActiveSchema = selectFaqSchema.pick({}).extend({
  isActive: z.boolean(),
});

export const insertFaqSchema = createInsertSchema(faqTable, {}).omit({
  created_at: true,
  deleted_at: true,
  updated_at: true,
});

export const deleteFaqSchema = selectFaqSchema.pick({
  id: true,
});

export const createFaqSchema = insertFaqSchema.omit({
  id: true,
});

export const updateFaqSchema = insertFaqSchema.required({
  id: true,
});

export const changeStatusFaqSchema = selectFaqSchema.pick({
  id: true,
  is_active: true,
});

export type FaqSelectDTO = z.infer<typeof selectFaqSchema>;

export type FaqFindByActiveDTO = z.infer<typeof findFaqsByActiveSchema>;

export type FaqDeleteDTO = z.infer<typeof deleteFaqSchema>;

export type FaqUpdateDTO = z.infer<typeof updateFaqSchema>;

export type FaqCreateDTO = z.infer<typeof createFaqSchema>;

export type FaqUpdateStatusDTO = z.infer<typeof changeStatusFaqSchema>;
