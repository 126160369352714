import { relations, type InferSelectModel } from 'drizzle-orm';
import {
  pgTable,
  timestamp,
  uuid,
  varchar,
  boolean,
} from 'drizzle-orm/pg-core';

import {
  roleTable,
  refreshTokenTable,
  type RefreshToken,
  type Role,
} from '../schema';

export const userTable = pgTable('user', {
  id: uuid('id')
    .primaryKey()
    .notNull()
    .$defaultFn(() => crypto.randomUUID()),
  name: varchar('name', { length: 40 }).notNull(),
  surname: varchar('surname', { length: 40 }).notNull(),
  email: varchar('email', { length: 80 }).notNull().unique(),
  password: varchar('password', { length: 255 }),
  photo_url: varchar('photo_url'),
  is_verified: boolean('is_verified').notNull().default(false),
  role_id: uuid('role_id')
    .notNull()
    .references(() => roleTable.id),
  created_at: timestamp('created_at', { withTimezone: true, mode: 'string' })
    .defaultNow()
    .notNull(),
  deleted_at: timestamp('deleted_at', { withTimezone: true, mode: 'string' }),
  updated_at: timestamp('updated_at', { withTimezone: true, mode: 'string' }),
});

export const usersRelations = relations(userTable, ({ one, many }) => ({
  role: one(roleTable, {
    fields: [userTable.role_id],
    references: [roleTable.id],
  }),
  refreshTokens: many(refreshTokenTable),
}));

export type User = InferSelectModel<typeof userTable>;
export interface UserWithRelations extends User {
  role?: Role;
  refreshTokens?: RefreshToken[];
}
