import { createRoute } from '@tanstack/react-router';

import { RegistrationsPage } from '@/pages/registrations/RegistrationPage';

import { adminLayoutRoute } from './adminLayoutRoute';

export const registrationsRoute = createRoute({
  getParentRoute: () => adminLayoutRoute,
  path: '/registrationList',
  component: RegistrationsPage,
});
