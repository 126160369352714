import { createRoute } from '@tanstack/react-router';

import { FaqsPage } from '@/pages/faqs/FaqsPage';

import { adminLayoutRoute } from './adminLayoutRoute';

export const faqsRoute = createRoute({
  getParentRoute: () => adminLayoutRoute,
  path: '/faqs',
  component: FaqsPage,
});
