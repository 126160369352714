import { Button, Tooltip } from '@utima/ui';
import type { ReactNode } from 'react';

type ActionItemProps = {
  icon: ReactNode;
  tooltip: string;
  onClick: () => void;
  disabled?: boolean;
};

export function ActionItem({
  icon,
  tooltip,
  onClick,
  disabled,
}: ActionItemProps) {
  return (
    <Tooltip title={tooltip}>
      <Button
        onClick={onClick}
        variant='ghost'
        disabled={disabled}
        className='p-2'
      >
        {icon}
      </Button>
    </Tooltip>
  );
}
