import { Button } from '@utima/ui';
import * as Form from '@utima/ui-informed';
import { useTranslation } from 'react-i18next';

import type { FeedbackType } from '../hooks/useFeedbacksCol';

type FeedbackFormProps = {
  data?: FeedbackType;
  onCancel?: () => void;
};

export function FeedbackForm({ data, onCancel }: FeedbackFormProps) {
  const { t } = useTranslation('feedbacks');

  return (
    <Form.Form className='flex w-full flex-col gap-2.5 p-3'>
      <Form.Input
        name='question'
        disabled
        initialValue={data?.messageData[0].content}
        label={t('form.question')}
      />
      <Form.TextArea
        label={t('form.response')}
        disabled
        style={{ height: '300px' }}
        name='response'
        initialValue={data?.messageData[1].content}
      />
      <Form.Input
        name='feedback'
        disabled
        initialValue={data?.messageFeedback}
        label={t('form.feedback')}
      />
      <Button onClick={onCancel} variant='primary'>
        {t('form.button')}
      </Button>
    </Form.Form>
  );
}
