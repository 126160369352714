import { Breadcrumb } from '@utima/ui';
import { Slash, Home } from 'lucide-react';
import React from 'react';

export interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface BreadcrumbComponentProps {
  items: BreadcrumbItem[];
}

export function BreadcrumbComponent({ items }: BreadcrumbComponentProps) {
  return (
    <Breadcrumb.List>
      <Breadcrumb.Item>
        <Breadcrumb.Link href='/'>
          <div className='flex flex-row items-center gap-3'>
            <Home />
            Home
          </div>
        </Breadcrumb.Link>
      </Breadcrumb.Item>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <Breadcrumb.Separator>
            <Slash />
          </Breadcrumb.Separator>
          <Breadcrumb.Item>
            {index === items.length - 1 ? (
              <Breadcrumb.Page>{item.label}</Breadcrumb.Page>
            ) : item.href ? (
              <Breadcrumb.Link href={item.href}>{item.label}</Breadcrumb.Link>
            ) : (
              <Breadcrumb.Page>{item.label}</Breadcrumb.Page>
            )}
          </Breadcrumb.Item>
        </React.Fragment>
      ))}
    </Breadcrumb.List>
  );
}
