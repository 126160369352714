import { relations, type InferSelectModel } from 'drizzle-orm';
import { pgTable, uuid, varchar } from 'drizzle-orm/pg-core';

import { userTable, type User } from '../schema';

export enum RoleEnum {
  Admin = 'admin',
  Support = 'support',
}

export const roleTable = pgTable('role', {
  id: uuid('id')
    .primaryKey()
    .notNull()
    .$defaultFn(() => crypto.randomUUID()),
  name: varchar('role', {
    length: 20,
    enum: [RoleEnum.Admin, RoleEnum.Support],
  }).notNull(),
});

export const rolesRelations = relations(roleTable, ({ many }) => ({
  users: many(userTable),
}));

export type Role = InferSelectModel<typeof roleTable>;
export interface RoleWithRelations extends Role {
  users?: Array<User>;
}
