import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import type z from 'zod';

import { operatorTable } from './operatorSchema';

export const insertOperatorSchema = createInsertSchema(operatorTable).omit({
  created_at: true,
  updated_at: true,
});

export const selectOperatorSchema = createSelectSchema(operatorTable);

export const createOperatorSchema = insertOperatorSchema.omit({
  id: true,
});

export const deleteOperatorSchema = selectOperatorSchema.pick({
  id: true,
});

export const updateOperatorSchema = insertOperatorSchema.required({
  id: true,
});

export const selectOperatorByBranchSchema = selectOperatorSchema.pick({
  branch: true,
});

export type OperatorCreateDTO = z.infer<typeof createOperatorSchema>;
export type OperatorDeleteDTO = z.infer<typeof deleteOperatorSchema>;
export type OperatorUpdateDTO = z.infer<typeof updateOperatorSchema>;
export type OperatorSelectByBranchDTO = z.infer<
  typeof selectOperatorByBranchSchema
>;
