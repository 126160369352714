import { useTranslation } from 'react-i18next';

type StatusTagProps = {
  status: boolean;
};

export function StatusTag({ status }: StatusTagProps) {
  const { t } = useTranslation('users');

  return status ? (
    <span className='text-green-600'>{t('status.active')}</span>
  ) : (
    <span className='text-red-600'>{t('status.inactive')}</span>
  );
}
