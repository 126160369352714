import {
  RoleEnum,
  type Operator,
} from '@form-factory-ai/admin-api/src/schema/schema';
import { FilePenLine, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { ActionItem } from '@/components/actionItem/ActionItem';
import { useMyStore } from '@/store/useMyStore';

import { useDeleteOperatorDialog } from '../hooks/useDeleteOperatorDialog';
import { useEditOperatorDialog } from '../hooks/useEditOperatorDialog';

export function ActionCellOperator({
  operatorData,
}: {
  operatorData: Operator;
}) {
  const { t } = useTranslation('users');

  // Load from store with newer approach
  const { user } = useMyStore();
  const isAdmin = user?.role === RoleEnum.Admin;

  const { openDialog: handleEditClick } = useEditOperatorDialog(operatorData);
  const { openDialog: handleDeleteClick } = useDeleteOperatorDialog(
    operatorData.id,
  );

  return (
    <div className='flex justify-center'>
      <ActionItem
        icon={<FilePenLine className='size-6' />}
        tooltip={t('edit.tooltip')}
        onClick={handleEditClick}
        disabled={!isAdmin}
      />
      <ActionItem
        icon={<Trash2 className='size-6' />}
        tooltip={t('delete.tooltip')}
        onClick={handleDeleteClick}
        disabled={!isAdmin}
      />
    </div>
  );
}
