import { Button } from '@utima/ui';
import { useTranslation } from 'react-i18next';

import type { FeedbackType } from '../hooks/useFeedbacksCol';
import { useReadFeedbackDialog } from '../hooks/useReadFeedbackDialog';

export function ActionCellFeedback({
  feedbackData,
}: {
  feedbackData: FeedbackType;
}) {
  const { t } = useTranslation('feedbacks');

  const { openDialog: handleReadFeedback } =
    useReadFeedbackDialog(feedbackData);

  return (
    <div className='flex justify-center'>
      <Button size='sm' className='m-1' outline onClick={handleReadFeedback}>
        {t('buttons.redirectToFeedback')}
      </Button>
    </div>
  );
}
