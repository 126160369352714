import type { Faq } from '@form-factory-ai/admin-api/src/schema/schema';
import { Switch, Table, Tooltip, toast } from '@utima/ui';
import { FilePenLine, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { ActionItem } from '@/components/actionItem/ActionItem';
import { trpc } from '@/services/trpc';

import { useDeleteFaqDialog } from '../hooks/useDeleteFaqDialog';
import { useEditFaqDialog } from '../hooks/useEditFaqDialog';

type FaqRowProps = {
  faqData: Faq;
};

export function FaqRow({ faqData }: FaqRowProps) {
  const { t } = useTranslation(['common', 'faqs']);

  const utils = trpc.useUtils();

  const changeStatusMutation = trpc.faq.changeStatusFaq.useMutation({
    onSuccess: () => {
      utils.faq.getFaqs.invalidate();
      toast.success(t('common:toast.success'), {
        description: t('faqs:changeStatus.success'),
      });
    },
    onError: error => {
      toast.error(t('common:toast.error'), {
        description: error.message,
      });
    },
  });

  const { openDialog: handleEditClick } = useEditFaqDialog(faqData);

  const { openDialog: handleDeleteClick } = useDeleteFaqDialog(faqData.id);

  return (
    <Table.Row>
      <Table.Col className='max-w-56 truncate'>{faqData.title}</Table.Col>
      <Table.Col className='max-w-72 truncate'>
        <Tooltip title={faqData.question}>
          <span>{faqData.question}</span>
        </Tooltip>
      </Table.Col>
      <Table.Col className=' max-w-72  truncate'>
        <Tooltip title={faqData.answer}>
          <span>{faqData.answer}</span>
        </Tooltip>
      </Table.Col>
      <Table.Col className='text-center'>
        <Switch
          checked={faqData.is_active}
          onCheckedChange={checked =>
            changeStatusMutation.mutate({
              id: faqData.id,
              is_active: checked,
            })
          }
        />
      </Table.Col>
      <Table.Col className='flex justify-center'>
        <ActionItem
          icon={<FilePenLine className='size-6' />}
          tooltip={t('faqs:edit.tooltip')}
          onClick={handleEditClick}
        />
        <ActionItem
          icon={<Trash2 className='size-6' />}
          tooltip={t('faqs:delete.tooltip')}
          onClick={handleDeleteClick}
        />
      </Table.Col>
    </Table.Row>
  );
}
