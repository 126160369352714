import { createRoute } from '@tanstack/react-router';

import { AuthLayout } from '@/components/authLayout/AuthLayout';

import { rootRoute } from './rootRoute';

export const authLayoutRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'authLayout',
  component: AuthLayout,
});
