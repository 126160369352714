import { Button } from '@utima/ui';
import { Trash2 } from 'lucide-react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import PlaceHolderIcon from '@/assets/icons/place-holder-image.svg';

type UserProfilePhotoProps = {
  title: string;
  photo: string | null;
  setPhoto: React.Dispatch<React.SetStateAction<string | null>>;
  onPhotoChange: (base64String: string | null) => void;
};

export function UserProfilePhoto({
  title,
  photo,
  setPhoto,
  onPhotoChange,
}: UserProfilePhotoProps) {
  const { t } = useTranslation(['users']);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const convertFileToBase64 = (
    file: Blob,
    callback: (result: string | ArrayBuffer | null) => void,
  ) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    reader.onerror = error => console.error('Error reading file', error);
    reader.readAsDataURL(file);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      convertFileToBase64(file, base64String => {
        // Check file size
        const MAX_SIZE = 200 * 1024; // 200kb
        if (file.size > MAX_SIZE) {
          // Resize the image if it exceeds the maximum size
          const img = new Image();
          img.addEventListener('load', () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d')!;
            const scaleFactor = MAX_SIZE / file.size;
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const resizedPhoto = canvas.toDataURL('image/jpeg', 0.7);
            onPhotoChange(resizedPhoto);
          });
          img.src = base64String as string;
        } else {
          // Use the original image if its size is within the limit
          onPhotoChange(base64String as string);
        }
      });
    }
  };

  const handleDeletePhoto = () => {
    if (photo?.startsWith('blob:')) {
      URL.revokeObjectURL(photo);
    }
    setPhoto('');
    onPhotoChange(null);
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <div className='flex flex-row gap-5'>
        <div>
          <img
            className='size-28 rounded-full'
            src={photo || PlaceHolderIcon}
            alt={title}
          />
        </div>
        <div className='flex flex-col justify-around'>
          <input
            type='file'
            accept='image/*'
            onChange={handleFileChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
          <Button size='sm' onClick={triggerFileInput}>
            {t('users:userPhoto.changeButton')}
          </Button>
          <Button
            size='sm'
            outline
            icon={<Trash2 className='size-4' />}
            onClick={handleDeletePhoto}
          >
            {t('users:userPhoto.deleteButton')}
          </Button>
        </div>
      </div>
      <span className='text-sm'> {t('users:userPhoto.photoInfo')}</span>
    </>
  );
}
