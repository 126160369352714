import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import z from 'zod';

import { userTable } from '../schema';

export const selectUserSchema = createSelectSchema(userTable, {
  email: schema => schema.email.email(),
});

export const insertUserSchema = createInsertSchema(userTable, {
  email: schema => schema.email.email(),
}).omit({
  created_at: true,
  deleted_at: true,
  updated_at: true,
  is_verified: true,
});

export const findUserByIdSchema = selectUserSchema.pick({
  id: true,
});

export const findUserByEmailSchema = selectUserSchema.pick({
  email: true,
});

export const loginUserSchema = selectUserSchema.pick({
  email: true,
  password: true,
});

export const createUserSchema = insertUserSchema.omit({
  id: true,
  password: true,
});

export const updateUserSchema = insertUserSchema
  .required({
    id: true,
  })
  .omit({
    email: true,
    password: true,
  });

export const updateProfileSchema = updateUserSchema.omit({
  role_id: true,
});

export const updateUserPasswordSchema = z.object({
  id: z.string(),
  password: z.string(),
});

export const verifyUserSchema = z.object({
  token: z.string(),
  password: z.string(),
});

export const findUserByJWT = z.object({
  token: z.string(),
});

export const changeUserPasswordSchema = z.object({
  email: z.string(),
  currentPassword: z.string(),
  newPassword: z.string(),
});

export type UserCreateDTO = z.infer<typeof createUserSchema>;

export type UserFindByIdDTO = z.infer<typeof findUserByIdSchema>;

export type UserLoginDTO = z.infer<typeof loginUserSchema>;

export type UserUpdateDTO = z.infer<typeof updateUserSchema>;

export type ProfileUpdateDTO = z.infer<typeof updateProfileSchema>;

export type UserFindByEmailDTO = z.infer<typeof findUserByEmailSchema>;

export type UserUpdatePasswordDTO = z.infer<typeof updateUserPasswordSchema>;

export type UserVerifyDTO = z.infer<typeof verifyUserSchema>;

export type UserFindByJWT = z.infer<typeof findUserByJWT>;
