import { DataTable } from '@/components/dataTable/DataTable';
import { threadsRoute } from '@/routes/threadsRoute';
import { trpc } from '@/services/trpc';
import type { PaginationParams } from '@/types';

import { useThreadsCol } from '../hooks/useThreadsCol';

export function UserThreadsTable() {
  const columns = useThreadsCol();
  const { userId } = threadsRoute.useParams();

  const useQuery = ({ pageSize, pageIndex }: PaginationParams) =>
    trpc.aiCoreRouter.getThreadsPaginated.useQuery({
      userId: userId,
      pageSize: pageSize,
      pageIndex: pageIndex,
    });

  return <DataTable columns={columns} useQuery={useQuery} />;
}
