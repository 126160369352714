import { DataTable } from '@/components/dataTable/DataTable';
import { trpc } from '@/services/trpc';
import type { PaginationParams } from '@/types';

import { useRegistrationCols } from '../hooks/useRegistrationsCols';

export function RegistrationsTable() {
  const columns = useRegistrationCols();

  const useQuery = ({ pageSize, pageIndex }: PaginationParams) =>
    trpc.registration.findPaginatedRegistrations.useQuery({
      pageSize: pageSize,
      pageIndex: pageIndex,
    });

  return <DataTable columns={columns} useQuery={useQuery} />;
}
