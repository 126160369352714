import { createColumnHelper, type Row } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionCellMessages } from '../messageManagementTable/ActionCell';

export interface MessagesType {
  userId: string;
  threadsCount: number;
  feedbacksCount: number;
  firstName?: string;
  lastName?: string;
  email?: string;
}

const columnHelper = createColumnHelper<MessagesType>();

export function useMessagesCol() {
  const { t } = useTranslation('messageManagement');

  const email = (row: Row<MessagesType>) =>
    row.original.firstName || row.original.lastName
      ? `, ${row.original.email}`
      : '';

  return useMemo(
    () => [
      columnHelper.accessor('threadsCount', {
        header: t('tableHead.threadsCount'),
      }),
      columnHelper.accessor('feedbacksCount', {
        header: t('tableHead.feedbackCount'),
      }),
      columnHelper.accessor('userId', {
        header: t('tableHead.userId') + ' (ID)',
      }),
      columnHelper.display({
        id: 'user',
        header: t('tableHead.userId'),
        cell: ({ row }) =>
          `${row.original.firstName ?? ''} ${row.original.lastName ?? ''}${email(row)}`,
      }),
      columnHelper.display({
        id: 'actions',
        header: t('tableHead.actions'),
        cell: ({ row }) => <ActionCellMessages userObject={row.original} />,
        meta: {
          className: 'w-52 text-center',
        },
      }),
    ],
    [t],
  );
}
