import { create, type StateCreator } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { controlSlice, type ControlSlice } from './controlSlice';
import { userSlice, type UserSlice } from './userSlice';

type MyStoreType = UserSlice & ControlSlice;

/**
 * Middleware for Zustand store adding devtools and persisting to session storage
 */
const middleware = (f: StateCreator<MyStoreType>) =>
  devtools(
    persist(f, {
      name: 'ff_admin_store',
      storage: createJSONStorage(() => sessionStorage),
      partialize: state => ({
        user: state.user,
        jwt: state.jwt,
      }),
    }),
  );

export const useMyStore = create<MyStoreType>()(
  middleware((set, get, api) => ({
    ...userSlice(set, get, api),
    ...controlSlice(set, get, api),
  })),
);
