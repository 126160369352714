import { toast } from '@utima/ui';
import { useTranslation } from 'react-i18next';

import type { DialogProps } from '@/components/dialogContext/DialogContext';
import { useDialog } from '@/components/dialogContext/useDialog';
import { trpc } from '@/services/trpc';

export function useDeleteOperatorDialog(id: string) {
  const { t } = useTranslation(['common', 'operators']);

  const utils = trpc.useUtils();

  const deleteOperatorMutation = trpc.operator.deleteOperator.useMutation({
    onSuccess: () => {
      toast.success(t('common:toast.success'), {
        description: t('operators:delete.success'),
      });
      utils.operator.paginatedOperators.invalidate();
    },
    onError: error => {
      toast.error(t('common:toast.error'), {
        description: error.message,
      });
    },
  });

  const deleteDialogProps = {
    title: t('operators:delete.dialog.title'),
    separatorClassName: 'w-36',
    subtitle: t('common:dialog.delete.text'),
    confirmText: t('common:dialog.delete.confirm'),
    cancelText: t('common:dialog.delete.cancel'),
    onConfirm: () => {
      deleteOperatorMutation.mutate({ id });
    },
  } as DialogProps;
  const { openDialog } = useDialog(deleteDialogProps);

  return { openDialog };
}
