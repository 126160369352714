import { createRoute } from '@tanstack/react-router';

import { ChangePasswordPage } from '@/pages/profile/ChangePasswordPage';

import { adminLayoutRoute } from './adminLayoutRoute';

export const changePasswordRoute = createRoute({
  getParentRoute: () => adminLayoutRoute,
  path: '/change-password',
  component: ChangePasswordPage,
});
