import { createRoute } from '@tanstack/react-router';

import { LoginPage } from '@/pages/login/LoginPage';

import { authLayoutRoute } from './authLayoutRoute';

export const loginRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: 'login',
  component: LoginPage,
});
