import { Table } from '@utima/ui';
import { useTranslation } from 'react-i18next';

import { trpc } from '@/services/trpc';

import { FaqRow } from './FaqRow';

export function FaqsTable() {
  const { t } = useTranslation('faqs');

  const faqsQuery = trpc.faq.getFaqs.useQuery();

  return (
    <Table.Root>
      <Table.Head>
        <Table.HRow>
          <Table.HCol>{t('tableHead.title')}</Table.HCol>
          <Table.HCol>{t('tableHead.question')}</Table.HCol>
          <Table.HCol>{t('tableHead.answer')}</Table.HCol>
          <Table.HCol className='w-28 text-center'>
            {t('tableHead.active')}
          </Table.HCol>
          <Table.HCol className='w-28 text-center'>
            {t('tableHead.actions')}
          </Table.HCol>
        </Table.HRow>
      </Table.Head>
      <Table.Body>
        {faqsQuery.data?.map(faq => <FaqRow key={faq.id} faqData={faq} />)}
      </Table.Body>
    </Table.Root>
  );
}
