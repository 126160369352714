import { Navigate, useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '@/components/pageTitle/PageTitle';
import { HOME_PAGE } from '@/constants/constants';
import { trpc } from '@/services/trpc';
import { useMyStore } from '@/store/useMyStore';

import { UserForm } from '../users/userForm/UserForm';

export function ProfilePage() {
  const { t } = useTranslation();
  const user = useMyStore(state => state.user);
  const navigate = useNavigate();

  if (!user) {
    return <Navigate to='/login' replace />;
  }

  const getUserQuery = trpc.user.getUserByEmail.useQuery({
    email: user.email,
  });

  return (
    <div className='flex flex-col gap-3'>
      <PageTitle title={t('titles.profile')} />
      <div className='flex w-full max-w-[760px] rounded-3xl bg-popover p-8'>
        <UserForm
          data={getUserQuery.data}
          onCancel={() => navigate({ to: HOME_PAGE })}
          profile
        />
      </div>
    </div>
  );
}
