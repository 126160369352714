import type { InferSelectModel } from 'drizzle-orm';
import {
  pgTable,
  uuid,
  boolean,
  text,
  timestamp,
  integer,
} from 'drizzle-orm/pg-core';

export const faqTable = pgTable('faq', {
  id: uuid('id')
    .primaryKey()
    .notNull()
    .$defaultFn(() => crypto.randomUUID()),
  title: text('title').notNull(),
  question: text('question').notNull(),
  answer: text('answer').notNull(),
  title_en: text('title_en').notNull(),
  question_en: text('question_en').notNull(),
  answer_en: text('answer_en').notNull(),
  click_count: integer('click_count').notNull().default(0),
  is_active: boolean('status').notNull().default(false),
  created_at: timestamp('created_at', { withTimezone: true, mode: 'string' })
    .defaultNow()
    .notNull(),
  deleted_at: timestamp('deleted_at', { withTimezone: true, mode: 'string' }),
  updated_at: timestamp('updated_at', { withTimezone: true, mode: 'string' }),
});

export type Faq = InferSelectModel<typeof faqTable>;
