import i18n, { type TFunction } from 'i18next';

export function translateRole(
  role: 'admin' | 'support',
  t: TFunction<'users'>,
) {
  return role === 'admin' ? t('role.admin') : t('role.support');
}

export const validatePassword = (
  value: unknown,
  _values: Record<string, unknown>,
) => {
  if (typeof value !== 'string') return;

  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasNumber = /\d/.test(value);
  const hasSpecialChar = /[^\dA-Za-z]/.test(value);

  const missingRequirements = [];

  if (value.length < minLength) {
    missingRequirements.push(i18n.t('form:errors.password.length'));
  }
  if (!hasUpperCase) {
    missingRequirements.push(i18n.t('form:errors.password.uppercase'));
  }
  if (!hasLowerCase) {
    missingRequirements.push(i18n.t('form:errors.password.lowercase'));
  }
  if (!hasNumber) {
    missingRequirements.push(i18n.t('form:errors.password.number'));
  }
  if (!hasSpecialChar) {
    missingRequirements.push(i18n.t('form:errors.password.specialChar'));
  }

  if (missingRequirements.length > 0) {
    const requirements = missingRequirements.join(', ');

    return i18n.t('form:errors.password.requirements', {
      requirements,
    });
  }
};

export const validateConfirmPassword = (
  value: unknown,
  { password }: Record<string, unknown>,
) => {
  if (!!password && !!value && password !== value) {
    return i18n.t('form:errors.password.match');
  }
};
