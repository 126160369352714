import { useTranslation } from 'react-i18next';

import {
  BreadcrumbComponent,
  type BreadcrumbItem,
} from '@/components/breadCrumb/BreadcrumbComponent';
import { PageTitle } from '@/components/pageTitle/PageTitle';

import { MessagesManagementTable } from './messageManagementTable/MessagesManagementTable';

export function ChatBotManagementPage() {
  const { t } = useTranslation(['common']);
  const managementLinks: BreadcrumbItem[] = [
    { label: t('common:titles.messageManagement'), href: '/messages' },
  ];

  return (
    <div className='flex flex-col gap-3'>
      <BreadcrumbComponent items={managementLinks} />

      <PageTitle title={t('common:titles.messageManagement')} />
      <MessagesManagementTable />
    </div>
  );
}
