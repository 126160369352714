import { useNavigate } from '@tanstack/react-router';
import { MessageSquareText, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { ActionItem } from '@/components/actionItem/ActionItem';
import { useDeleteExternalUserDialog } from '@/pages/users/hooks/useDeleteExternalUserDialog.ts';

import type { MessagesType } from '../hooks/useMessagesCol';

export function ActionCellMessages({
  userObject,
}: {
  userObject: MessagesType;
}) {
  const { t } = useTranslation(['messageManagement']);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({
      to: '/messages/threads/$userId',
      params: { userId: userObject.userId },
    });
  };

  const { openDialog: handleDeleteUser } = useDeleteExternalUserDialog(
    userObject.userId,
  );

  return (
    <div className='flex justify-center'>
      <ActionItem
        icon={<MessageSquareText className='size-6' />}
        tooltip={t('messageManagement:buttons.redirectToThreads')}
        onClick={handleClick}
      />
      <ActionItem
        icon={<Trash2 className='size-6' />}
        tooltip={t('messageManagement:buttons.delete')}
        onClick={handleDeleteUser}
      />
    </div>
  );
}
