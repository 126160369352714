import {
  createOperatorSchema,
  updateOperatorSchema,
  type Operator,
  type OperatorCreateDTO,
} from '@form-factory-ai/admin-api/src/schema/schema';
import { toast } from '@utima/ui';
import * as Form from '@utima/ui-informed';
import { Select } from '@utima/ui-informed';
import { useTranslation } from 'react-i18next';

import { FormActions } from '@/components/formActions/FormActions';
import { trpc } from '@/services/trpc';

import { OperatorBranchSelect } from './OperatorBranchSelect';

type OperatorFormProps = {
  data?: Operator;
  onCancel?: () => void;
  onSubmit?: () => void;
};

export function OperatorForm({ data, onCancel, onSubmit }: OperatorFormProps) {
  const { t } = useTranslation('operators');
  const { t: tForm } = useTranslation('form');

  const validatePhone = (value: unknown): string | undefined => {
    const pattern = /^\+(?:\d{3}\s?){3}\d{3}$/;

    if (typeof value === 'string' && !pattern.test(value)) {
      return t('form.invalid');
    }
  };

  const cities = [
    { id: '1', name: 'Praha' },
    { id: '2', name: 'Brno' },
    { id: '3', name: 'Ostrava' },
  ];

  const utils = trpc.useUtils();

  const createOperatorMutation = trpc.operator.createOperator.useMutation();
  const updateOperatorMutation = trpc.operator.updateOperator.useMutation();

  const handleSubmit = async (
    formState: Form.TypedFormState<OperatorCreateDTO | undefined>,
  ) => {
    const { values } = formState;

    if (!values) {
      return;
    }

    try {
      await (data
        ? updateOperatorMutation.mutateAsync({ id: data.id, ...values })
        : createOperatorMutation.mutateAsync(values));

      toast.success(tForm('actions.success.title'), {
        description: tForm('actions.success.message'),
      });

      utils.operator.paginatedOperators.invalidate();

      onSubmit?.();
    } catch {
      toast.error(tForm('actions.fail.title'), {
        description: tForm('actions.fail.message'),
      });
    }
  };

  return (
    <Form.Form
      initialValues={data}
      onSubmit={handleSubmit}
      zodSchema={data ? updateOperatorSchema : createOperatorSchema}
      className='flex w-full flex-col gap-2.5 p-3'
    >
      <Form.Input
        name='name'
        label={t('form.name')}
        placeholder={t('form.name')}
        required
      />
      <Form.Input
        name='email'
        label={t('form.email')}
        placeholder={t('form.email')}
        required
      />
      <Form.Input
        name='phone'
        label={t('form.phone')}
        validate={validatePhone}
        placeholder={t('form.phone')}
        required
      />
      <Select
        name='city'
        label={t('form.city')}
        placeholder={t('form.city')}
        required
      >
        {cities.map(city => (
          <Select.Item key={city.id} value={city.name}>
            {city.name}
          </Select.Item>
        ))}
      </Select>
      <OperatorBranchSelect />
      <FormActions onCancel={onCancel} />
    </Form.Form>
  );
}
