import { Link } from '@tanstack/react-router';
import { Avatar, Dropdown } from '@utima/ui';
import { LockKeyhole, Settings, SquareArrowLeft } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CZFlag from '@/assets/flags/CZ.svg';
import GBFlag from '@/assets/flags/GB.svg';
import FFLogo from '@/assets/logos/FF-logo.svg';
import { router } from '@/routes/router';
import { trpc } from '@/services/trpc';
import { useMyStore } from '@/store/useMyStore';

function TopBar() {
  const { t } = useTranslation();
  const [flag, setFlag] = useState(CZFlag);

  const toggleFlag = () => {
    setFlag(prevFlag => (prevFlag === CZFlag ? GBFlag : CZFlag));
  };

  const logout = useMyStore(state => state.logout);
  const user = useMyStore(state => state.user);
  const logoutMutation = trpc.auth.logout.useMutation({
    onError: error => {
      alert('logout failed');
      console.error(error);
    },
    onSettled: () => {
      logout();
      router.navigate({ to: '/login' });
    },
  });

  return (
    <div className='flex w-full shrink-0 items-center justify-between bg-black px-8 py-3'>
      <img src={FFLogo} alt='FFLogo' className='size-6' />
      <div className='flex items-center gap-9'>
        <Dropdown.Root>
          <Dropdown.Trigger>
            <img src={flag} alt='CZFlag' className='h-5 w-8' />
          </Dropdown.Trigger>
          <Dropdown.Content
            sideOffset={12}
            className='flex min-w-8 flex-col gap-1 border-0 bg-black px-0.5 py-3'
          >
            <Dropdown.Item onClick={toggleFlag}>
              <img
                src={flag === CZFlag ? GBFlag : CZFlag}
                alt='Toggle Flag'
                className='h-5 w-8'
              />
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Root>
        <Dropdown.Root>
          <Dropdown.Trigger>
            <Avatar
              className='cursor-pointer'
              src={
                user?.photo_url
                  ? user.photo_url
                  : 'https://randomuser.me/api/portraits/men/1.jpg'
              }
            />
          </Dropdown.Trigger>
          <Dropdown.Content
            sideOffset={12}
            className='flex flex-col gap-1 border-0 bg-black px-0 py-3'
          >
            <Dropdown.Item asChild>
              <Link to='/profile'>
                <Settings className='size-6' />
                {t('titles.profile')}
              </Link>
            </Dropdown.Item>
            <Dropdown.Item asChild>
              <Link to='/change-password'>
                <LockKeyhole className='size-6' />
                {t('titles.changePassword')}
              </Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => logoutMutation.mutate()}>
              <SquareArrowLeft className='size-6' />
              {t('titles.logout')}
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Root>
      </div>
    </div>
  );
}

export default TopBar;
