import type { Operator } from '@form-factory-ai/admin-api/src/schema/schema';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DialogContext,
  type DialogProps,
} from '@/components/dialogContext/DialogContext';
import { useDialog } from '@/components/dialogContext/useDialog';

import { OperatorForm } from '../operatorForm/OperatorForm';

export function useEditOperatorDialog(operatorData: Operator) {
  const { t } = useTranslation('operators');

  const { setOpen } = useContext(DialogContext);
  const closeDialog = () => setOpen(false);

  const updateDialogProps = {
    title: t('updateOperator'),
    separatorClassName: 'w-36',
    contentClassName: 'max-w-xl',
    children: (
      <OperatorForm
        data={operatorData}
        onCancel={closeDialog}
        onSubmit={closeDialog}
      />
    ),
  } as DialogProps;
  const { openDialog } = useDialog(updateDialogProps);

  return { openDialog };
}
