import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { Toast } from '@utima/ui';
import { useEffect } from 'react';

import { trpc } from '@/services/trpc';
import { useMyStore } from '@/store/useMyStore';

import { DialogProvider } from '../dialogContext/DialogContext';

export function RootLayout() {
  const jwt = useMyStore(state => state.jwt);
  const setUser = useMyStore(state => state.setUser);
  const userQuery = trpc.user.me.useQuery({
    token: jwt!!, // Ensure that jwt is not null
  });

  useEffect(() => {
    if (!userQuery.data) {
      return;
    }
    setUser({
      email: userQuery.data.email,
      name: userQuery.data.name,
      role: userQuery.data.role.name,
      photo_url: userQuery.data.photo_url,
    });
  }, [setUser, userQuery.data]);

  return (
    <>
      <DialogProvider>
        <Outlet />
      </DialogProvider>
      <Toast closeButton position='top-right' />
      {!import.meta.env.PROD && (
        <>
          <TanStackRouterDevtools />
          <ReactQueryDevtools />
        </>
      )}
    </>
  );
}
