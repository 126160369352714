import { useContext } from 'react';

import { DialogContext, type DialogProps } from './DialogContext';

export function useDialog(dialogProps: DialogProps) {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error('No DialogProvider found when calling useDialog.');
  }

  const openDialog = () => {
    context.setDialogProps(dialogProps);
    context.setOpen(true);
  };

  return {
    openDialog,
  };
}
