import * as Form from '@utima/ui-informed';
import { useTranslation } from 'react-i18next';

import { validateConfirmPassword, validatePassword } from '@/utils/utils';

type ConfirmPasswordInputsProps = {
  firstPasswordLabel?: string;
};

export function ConfirmPasswordInputs({
  firstPasswordLabel,
}: ConfirmPasswordInputsProps) {
  const { t } = useTranslation('auth');

  return (
    <>
      <Form.Input
        passwordPreview
        name='password'
        type='password'
        label={firstPasswordLabel ?? t('input.password')}
        placeholder={firstPasswordLabel ?? t('input.password')}
        validate={validatePassword}
        showErrorIfError
        required
      />
      <Form.Input
        passwordPreview
        name='confirmPassword'
        type='password'
        label={t('input.confirmPassword')}
        placeholder={t('input.password')}
        validate={validateConfirmPassword}
        validateOn='change'
        showErrorIfTouched
        showOptional={false}
        validateWhen={['password']}
        required
      />
    </>
  );
}
