import type { Registration } from '@form-factory-ai/admin-api/src/schema/registration/registrationSchema';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const columnHelper = createColumnHelper<Registration>();

export function useRegistrationCols() {
  const { t } = useTranslation('registrations');

  return useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('tableHead.name'),
      }),
      columnHelper.accessor('email', {
        header: t('tableHead.email'),
      }),
      columnHelper.accessor('club', {
        header: t('tableHead.club'),
      }),
      columnHelper.accessor('membership_name', {
        header: t('tableHead.membershipName'),
      }),
    ],
    [t],
  );
}
