import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import zodCs from 'zod-i18n-map/locales/cs/zod.json';
import zodEn from 'zod-i18n-map/locales/en/zod.json';

import type auth from '../../public/locales/cs/auth.json';
import type common from '../../public/locales/cs/common.json';
import type faqs from '../../public/locales/cs/faqs.json';
import type feedbacks from '../../public/locales/cs/feedbacks.json';
import type form from '../../public/locales/cs/form.json';
import type messageManagement from '../../public/locales/cs/messageManagement.json';
import type operators from '../../public/locales/cs/operators.json';
import type registrations from '../../public/locales/cs/registrations.json';
import type threads from '../../public/locales/cs/threads.json';
import type users from '../../public/locales/cs/users.json';

export const DEFAULT_NAMESPACE = 'common';
export const SUPPORTED_LANGUAGES = ['cs', 'en'];

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'cs',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    defaultNS: DEFAULT_NAMESPACE,
    supportedLngs: SUPPORTED_LANGUAGES,
  });

i18n.addResourceBundle('cs', 'zod', zodCs);
i18n.addResourceBundle('en', 'zod', zodEn);
z.setErrorMap(zodI18nMap);

export { i18n };

// when new namespace is added, add it here
// we will be creating type just from cs language because that should be always full
export interface I18nCsResources {
  common: typeof common;
  auth: typeof auth;
  form: typeof form;
  faqs: typeof faqs;
  users: typeof users;
  operators: typeof operators;
  registrations: typeof registrations;
  feedbacks: typeof feedbacks;
  threads: typeof threads;
  messageManagement: typeof messageManagement;
}

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof DEFAULT_NAMESPACE;
    resources: I18nCsResources;
  }
}
