/**
 * Generate pagination for a table component, result is an array of page numbers
 * and ellipsis placeholders.
 */
export function generatePagination(
  pageIndex: number,
  pageCount: number,
): ('...' | number)[] {
  const pages: ('...' | number)[] = [];
  let startPage = 1;
  let endPage = pageCount;

  if (pageCount > 5) {
    if (pageIndex <= 3) {
      endPage = 5;
    } else if (pageIndex + 3 >= pageCount) {
      startPage = pageCount - 4;
    } else {
      startPage = pageIndex;
      endPage = pageIndex + 2;
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  if (pages[0] !== 1) {
    pages.unshift('...');
    pages.unshift(1);
  }

  if (pages.at(-1) !== pageCount) {
    pages.push('...', pageCount);
  }

  return pages;
}

/**
 * Hook to generate pagination for a table component, result is
 * an array of page numbers and ellipsis placeholders with additional
 * helper values.
 */
export function usePagination(pageIndex: number, pageCount: number) {
  const pagination = generatePagination(pageIndex, pageCount);
  const hasNext = pageIndex < pageCount - 1;
  const hasPrevious = pageIndex > 0;

  return {
    pagination,
    hasNext,
    hasPrevious,
  };
}
