import { Button } from '@utima/ui';
import DOMPurify from 'dompurify';
import { ArrowLeft } from 'lucide-react';
import { marked } from 'marked';
import { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useMyStore } from '@/store/useMyStore';

interface SanitizedMessage {
  systemTextMarked: string;
  userTextMarked: string;
  role?: string;
}

const preprocessMarkdown = (markdownText: string) => {
  // This regex targets lines between triple backticks
  const codeBlockRegex = /```[\S\s]*?```/g;

  return markdownText.replaceAll(codeBlockRegex, '');
};

export function LeftSidebarHistory() {
  const ref = useRef<HTMLDivElement>(null);

  const messageItems = useMyStore(state => state.items);
  const barStatus = useMyStore(state => state.threadsBar);
  const closeBar = useMyStore(state => state.setOpenThreadsBar);
  const [sanitizedMessages, setSanitizedMessages] = useState<
    SanitizedMessage[]
  >([]);
  const { t } = useTranslation('messageManagement');

  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeBar();
      }
    }

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [closeBar]);

  useEffect(() => {
    async function sanitizeMessages() {
      if (!messageItems) return;

      const sanitized = await Promise.all(
        messageItems.map(async messageItem => {
          const systemMessage = preprocessMarkdown(
            DOMPurify.sanitize(messageItem.conversationData[0].content || ''),
          );
          const userMessage = preprocessMarkdown(
            DOMPurify.sanitize(messageItem.conversationData[1].content || ''),
          );

          const systemTextMarked = await marked(systemMessage);
          const userTextMarked = await marked(userMessage);

          return {
            systemTextMarked,
            userTextMarked,
            role: messageItem.conversationData[0].role,
          };
        }),
      );

      setSanitizedMessages(sanitized);
    }

    sanitizeMessages();
  }, [messageItems]);

  return barStatus ? (
    <div
      ref={ref}
      className='absolute right-0 z-50 flex h-[calc(100vh-64px)] w-1/5 flex-col items-center gap-2 overflow-y-auto border-l border-gray-300 bg-white px-4 py-10 '
    >
      <div className='relative flex w-full flex-row items-center justify-center gap-3'>
        <Button
          className='absolute left-0'
          icon={<ArrowLeft />}
          size='xs'
          outline
          onClick={closeBar}
        />

        <h2 className='text-2xl font-bold'>{t('sideBar.title')}</h2>
      </div>
      <div className='flex flex-col gap-2'>
        {sanitizedMessages.map((sanitizedMessage, index) => (
          <Fragment key={index}>
            <div className='flex flex-col items-start gap-2'>
              <span className='text-sm font-bold text-teal-500'>
                {t('sideBar.user')}
              </span>
              <div
                className='max-w-[90%] break-words rounded-2xl bg-teal-500 px-3.5 py-3 text-sm text-black'
                dangerouslySetInnerHTML={{
                  __html: sanitizedMessage.systemTextMarked,
                }}
              />
            </div>
            <div className='flex flex-col items-end gap-2'>
              <span className='text-sm font-bold text-black'>
                {t('sideBar.chatbot')}
              </span>
              <div
                className='max-w-[90%] break-words rounded-2xl bg-gray-200 px-3.5 py-3 text-sm text-black'
                dangerouslySetInnerHTML={{
                  __html: sanitizedMessage.userTextMarked,
                }}
              />
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  ) : null;
}
