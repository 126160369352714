import { createRoute } from '@tanstack/react-router';

import { VerificationPage } from '@/pages/verification/VerificationPage';

import { authLayoutRoute } from './authLayoutRoute';

type VerifySearch = {
  token: string;
};

export const verifyRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: 'verification',
  component: VerificationPageWrapper,
  validateSearch: (search: Record<string, unknown>): VerifySearch => {
    return {
      token: (search.token as string) || '',
    };
  },
});

function VerificationPageWrapper() {
  const { token } = verifyRoute.useSearch();

  return <VerificationPage token={token} />;
}
