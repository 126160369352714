import { createRoute } from '@tanstack/react-router';

import { OperatorsPage } from '@/pages/operators/OperatorsPage';

import { adminLayoutRoute } from './adminLayoutRoute';

export const operatorRoute = createRoute({
  getParentRoute: () => adminLayoutRoute,
  path: '/operators',
  component: OperatorsPage,
});
