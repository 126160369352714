import type { UserWithRelations } from '@form-factory-ai/admin-api/src/schema/schema';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DialogContext,
  type DialogProps,
} from '@/components/dialogContext/DialogContext';
import { useDialog } from '@/components/dialogContext/useDialog';

import { UserForm } from '../userForm/UserForm';

export function useEditUserDialog(userData: UserWithRelations) {
  const { t } = useTranslation('users');

  const { setOpen } = useContext(DialogContext);
  const closeDialog = () => setOpen(false);

  const updateDialogProps = {
    title: t('updateUser'),
    separatorClassName: 'w-36',
    contentClassName: 'max-w-xl',
    children: (
      <UserForm
        data={userData}
        onCancel={closeDialog}
        onSubmit={closeDialog}
        profile={false}
      />
    ),
  } as DialogProps;
  const { openDialog } = useDialog(updateDialogProps);

  return { openDialog };
}
