import { createRoute } from '@tanstack/react-router';

import { ResetPasswordPage } from '@/pages/resetPassword/ResetPasswordPage';

import { authLayoutRoute } from './authLayoutRoute';

type ResetPasswordSearch = {
  token: string;
};

export const resetPasswordRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: 'reset-password',
  component: ResetPasswordPageWrapper,
  validateSearch: (search: Record<string, unknown>): ResetPasswordSearch => {
    return {
      token: (search.token as string) || '',
    };
  },
});

function ResetPasswordPageWrapper() {
  const { token } = resetPasswordRoute.useSearch();

  return <ResetPasswordPage token={token} />;
}
