import { createRoute, redirect } from '@tanstack/react-router';

import { AdminLayout } from '@/components/adminLayout/AdminLayout';
import { useMyStore } from '@/store/useMyStore';

import { rootRoute } from './rootRoute';

export const adminLayoutRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'adminLayout',
  component: AdminLayout,
  beforeLoad: async () => {
    if (useMyStore.getState().jwt === null) {
      throw redirect({
        to: '/login',
        search: {
          // Use the current location to power a redirect after login
          redirect: location.pathname,
        },
      });
    }
  },
});
