import { Button } from '@utima/ui';
import { CirclePlus } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  BreadcrumbComponent,
  type BreadcrumbItem,
} from '@/components/breadCrumb/BreadcrumbComponent';
import { PageTitle } from '@/components/pageTitle/PageTitle';

import { FaqsTable } from './faqsTable/FaqsTable';
import { useCreateFaqDialog } from './hooks/useCreateFaqDialog';

export function FaqsPage() {
  const { t } = useTranslation(['faqs', 'common']);

  const { openDialog: handleAddFaqClick } = useCreateFaqDialog();

  const faqsLinks: BreadcrumbItem[] = [
    { label: t('common:titles.faqs'), href: '/faqs' },
  ];

  return (
    <div className='flex  flex-col gap-3   '>
      <BreadcrumbComponent items={faqsLinks} />

      <PageTitle title={t('common:titles.faqs')} />
      <div className='flex justify-end'>
        <Button
          icon={<CirclePlus className='size-4' />}
          size='sm'
          onClick={handleAddFaqClick}
        >
          {t('faqs:addFAQ')}
        </Button>
      </div>
      <FaqsTable />
    </div>
  );
}
