import { toast } from '@utima/ui';
import { useTranslation } from 'react-i18next';

import type { DialogProps } from '@/components/dialogContext/DialogContext';
import { useDialog } from '@/components/dialogContext/useDialog';
import { trpc } from '@/services/trpc';

export function useResendEmailDialog(id: string) {
  const { t } = useTranslation(['common', 'users']);

  const resendEmailMutation = trpc.user.resendEmail.useMutation({
    onSuccess: () => {
      toast.success(t('common:toast.success'), {
        description: t('users:resendEmail.success'),
      });
    },
    onError: error => {
      toast.error(t('common:toast.error'), {
        description: error.message,
      });
    },
  });

  const resendDialogProps = {
    title: t('users:resendEmail.dialog.title'),
    separatorClassName: 'w-36',
    subtitle: t('users:resendEmail.dialog.text'),
    confirmText: t('users:resendEmail.dialog.confirm'),
    cancelText: t('users:resendEmail.dialog.cancel'),
    onConfirm: () => {
      resendEmailMutation.mutate({ id });
    },
  } as DialogProps;
  const { openDialog } = useDialog(resendDialogProps);

  return { openDialog };
}
