import { useTranslation } from 'react-i18next';

export function TrpcPage() {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col items-center gap-3'>
      <h1 className='text-black'>{t('trpcPage.title')}</h1>
    </div>
  );
}
