import {
  loginUserSchema,
  type UserLoginDTO,
} from '@form-factory-ai/admin-api/src/schema/schema';
import { Link, useNavigate, useSearch } from '@tanstack/react-router';
import { Button, toast } from '@utima/ui';
import * as Form from '@utima/ui-informed';
import { useTranslation } from 'react-i18next';

import { HOME_PAGE } from '@/constants/constants';
import { trpc } from '@/services/trpc';
import { useMyStore } from '@/store/useMyStore';

const initialValues: UserLoginDTO = {
  email: '',
  password: '',
};

export function LoginPage() {
  const { t } = useTranslation(['common', 'auth']);
  const setJwt = useMyStore(state => state.setJwt);
  const setUser = useMyStore(state => state.setUser);
  const logout = useMyStore(state => state.logout);
  const navigate = useNavigate();
  const loginMutation = trpc.auth.login.useMutation();

  const search = useSearch({
    strict: false,
  });

  const onSubmit = (formState: Form.TypedFormState<UserLoginDTO>) => {
    return loginMutation.mutate(formState.values, {
      onError: _error => {
        logout();
        toast.error(t('common:toast.error'), {
          description: t('auth:login.error'),
        });
      },
      onSuccess: data => {
        setJwt(data.access);
        setUser(data.user);
        if (search.redirect) {
          navigate({ to: search.redirect, replace: true });
        } else {
          navigate({ to: HOME_PAGE });
        }
      },
    });
  };

  return (
    <Form.Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      zodSchema={loginUserSchema}
      className='flex w-full flex-col gap-6'
    >
      <h1 className='text-center text-2xl font-bold'>
        {t('auth:login.title')}
      </h1>
      <Form.Input
        name='email'
        type='email'
        label={t('auth:input.email')}
        placeholder={t('auth:input.email')}
        required
      />
      <Form.Input
        passwordPreview
        name='password'
        type='password'
        label={t('auth:input.password')}
        placeholder={t('auth:input.password')}
        required
      />
      <Button variant='primary' size='lg' type='submit'>
        {t('auth:login.button')}
      </Button>
      <div className='flex justify-center'>
        <Link to='/forgotten-password' className='text-sm'>
          {t('auth:login.toForgottenPassword')}
        </Link>
      </div>
    </Form.Form>
  );
}
