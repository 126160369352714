import {
  RoleEnum,
  type UserWithRelations,
} from '@form-factory-ai/admin-api/src/schema/schema';
import { MailCheck, FilePenLine, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { ActionItem } from '@/components/actionItem/ActionItem';
import { useMyStore } from '@/store/useMyStore';

import { useDeleteUserDialog } from '../hooks/useDeleteUserDialog';
import { useEditUserDialog } from '../hooks/useEditUserDialog';
import { useResendEmailDialog } from '../hooks/useResendEmailDialog';

export function ActionCell({ userData }: { userData: UserWithRelations }) {
  const { t } = useTranslation('users');

  const user = useMyStore(state => state.user);
  const isAdmin = user?.role === RoleEnum.Admin;

  const { openDialog: handleEditClick } = useEditUserDialog(userData);
  const { openDialog: handleResendClick } = useResendEmailDialog(userData.id);
  const { openDialog: handleDeleteClick } = useDeleteUserDialog(userData.id);

  return (
    <div className='flex justify-center'>
      <ActionItem
        icon={<FilePenLine className='size-6' />}
        tooltip={t('edit.tooltip')}
        onClick={handleEditClick}
        disabled={!isAdmin}
      />
      <ActionItem
        icon={<Trash2 className='size-6' />}
        tooltip={t('delete.tooltip')}
        onClick={handleDeleteClick}
        disabled={!isAdmin || userData.email === user?.email}
      />
      <ActionItem
        icon={<MailCheck className='size-6' />}
        tooltip={t('resendEmail.tooltip')}
        onClick={handleResendClick}
        disabled={!isAdmin || userData.is_verified}
      />
    </div>
  );
}
