import { Link } from '@tanstack/react-router';
import type { ReactNode } from 'react';

interface SideBarItemProps {
  icon: ReactNode;
  title: string;
  url: string;
}

function SideBarItem({ icon, title, url }: SideBarItemProps) {
  return (
    <Link to={url}>
      <div className='flex w-full items-center gap-6 rounded-lg p-3 hover:bg-gray-800'>
        <div className='flex flex-row items-center gap-2'>
          {icon}
          <span className='text-sm font-bold'>{title}</span>
        </div>
      </div>
    </Link>
  );
}

export default SideBarItem;
