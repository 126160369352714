import type { Faq } from '@form-factory-ai/admin-api/src/schema/schema';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DialogContext,
  type DialogProps,
} from '@/components/dialogContext/DialogContext';
import { useDialog } from '@/components/dialogContext/useDialog';

import { FaqForm } from '../faqForm/FaqForm';

export function useEditFaqDialog(faqData: Faq) {
  const { t } = useTranslation('faqs');

  const { setOpen } = useContext(DialogContext);
  const closeDialog = () => setOpen(false);

  const addFaqDialogProps: DialogProps = {
    title: t('updateFAQ'),
    separatorClassName: 'w-24',
    contentClassName: 'max-w-2xl',
    children: (
      <FaqForm data={faqData} onCancel={closeDialog} onSubmit={closeDialog} />
    ),
  };
  const { openDialog } = useDialog(addFaqDialogProps);

  return { openDialog };
}
