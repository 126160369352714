import { setComponentOverrides } from '@utima/ui/overrides';

/**
 * @utima/ui component overrides
 */
setComponentOverrides({
  global: {
    // we don't use any ring on focus anywhere in design
    ring: 'ring-transparent focus:ring-0 focus:ring-offset-0',
  },
  badge: {
    badge: 'rounded-[30px]',
    variants: {
      variant: {
        // badges are used for user roles
        primary: 'bg-green-100 border-green-100 text-green-600',
        secondary: 'bg-blue-100 border-blue-100 text-blue-700',
      },
    },
  },
  button: {
    variants: {
      variant: {
        primary:
          'hover:bg-teal-400 hover:border-teal-400 active:bg-teal-600 active:border-teal-600',
        secondary:
          'border-[1px] border-secondary-fg/10 hover:border-teal-500 active:bg-teal-100',
        //this variant is for buttons without background - icon action buttons
        ghost:
          'hover:bg-transparent hover:text-red active:bg-transparent active:text-teal-500 disabled:opacity-10 flex-grow-0',
      },
      size: {
        md: 'py-3 px-4 w-full',
        lg: 'py-3 px-4 w-full',
      },
    },
  },
  dialog: {
    footer: 'px-4 pb-4 sm:space-x-4',
    header: 'items-center',
    title: 'text-center text-2xl font-bold pb-4',
    description: 'text-center text-foreground pt-4',
    content: {
      wrapper: 'gap-6 p-12 sm:rounded-3xl',
      close: 'hidden',
    },
  },
  dropdown: {
    item: 'bg-black text-white py-2 px-4 rounded-lg focus:bg-gray-800 font-condensed text-sm font-bold leading-tight gap-2',
  },
  input: {
    variants: {
      size: {
        md: 'h-auto px-5 py-3.5',
      },
    },
    passwordPreview: {
      icon: 'size-6 text-black',
    },
  },
  label: {
    label: 'font-bold',
  },
  select: {
    // tmp fix for select placeholder
    trigger: 'data-[placeholder]:text-placeholder',
    variants: {
      size: {
        md: 'h-auto px-5 py-3.5',
      },
    },
  },
  separator: {
    separator: 'rounded-sm',
  },
  table: {
    head: 'text-foreground bg-table',
    col: 'px-2 py-0 h-auto',
    row: 'bg-white hover:bg-emerald-50 border-b border-table-border',
    hRow: 'text-foreground bg-table',
  },
  toast: {
    toast: '[&>[data-icon]]:mt-0.5',
    error: '[&_[data-description]]:text-danger/80',
  },
}); // import function form utimaUI instead of object from @utima/ui/overrides
