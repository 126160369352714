import { Send } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function VerificationInvalidTokenPage() {
  const { t } = useTranslation('auth');

  return (
    <div className='flex flex-col items-center gap-6'>
      <h1 className='text-center text-2xl font-bold'>
        {t('verification.title')}
      </h1>
      <div className='rounded bg-primary/10 px-4 py-3'>
        <Send className='size-[42px] text-primary' />
      </div>
      <div className='px-10 text-center text-sm'>
        {t('verification.invalidToken')}
      </div>
    </div>
  );
}
