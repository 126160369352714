import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import type z from 'zod';

import { registrationTable } from './registrationSchema';

export const insertRegistrationSchema = createInsertSchema(
  registrationTable,
  {},
).omit({
  created_at: true,
  updated_at: true,
  id: true,
});

const _selectRegistrationSchema = createSelectSchema(registrationTable, {});

export type RegistrationCreateDTO = z.infer<typeof insertRegistrationSchema>;
export type RegistrationFindDTO = z.infer<typeof _selectRegistrationSchema>;
