import { Button } from '@utima/ui';
import { useFormContext } from '@utima/ui-informed';
import { useTranslation } from 'react-i18next';

type FormActionsProps = {
  onCancel?: () => void;
  cancelText?: string;
  confirmText?: string;
};

export function FormActions({
  onCancel,
  cancelText,
  confirmText,
}: FormActionsProps) {
  const { t } = useTranslation('form');
  const { loading } = useFormContext();

  return (
    <div className='flex gap-4'>
      {onCancel && (
        <Button disabled={loading} onClick={onCancel} variant='secondary'>
          {cancelText ?? t('actions.cancel')}
        </Button>
      )}
      <Button
        loading={loading}
        disabled={loading}
        variant='primary'
        type='submit'
      >
        {confirmText ?? t('actions.save')}
      </Button>
    </div>
  );
}
