import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionCellFeedback } from '../userFeedbackTable/ActionCell';

export interface MessageData {
  id: number;
  name: string;
  content?: string;
  role?: string;
}

export interface FeedbackType {
  createdAt: string;
  messageFeedback: string;
  messageData: MessageData[];
}

const columnHelper = createColumnHelper<FeedbackType>();

export function useFeedbacksCol() {
  const { t } = useTranslation('feedbacks');

  return useMemo(
    () => [
      columnHelper.accessor('createdAt', {
        header: t('tableHead.createAt'),
      }),
      columnHelper.accessor('messageFeedback', {
        header: t('tableHead.feedback'),
      }),
      columnHelper.display({
        id: 'actions',
        header: t('tableHead.actions'),
        cell: ({ row }) => <ActionCellFeedback feedbackData={row.original} />,
        meta: {
          className: 'w-52 text-center',
        },
      }),
    ],
    [t],
  );
}
