import { createRoute } from '@tanstack/react-router';

import { TrpcPage } from '@/pages/trpc/TrpcPage';

import { adminLayoutRoute } from './adminLayoutRoute';

export const trpcRoot = createRoute({
  getParentRoute: () => adminLayoutRoute,
  path: '/',
  component: TrpcPage,
});
