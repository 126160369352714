import { useNavigate } from '@tanstack/react-router';
import { Button, toast } from '@utima/ui';
import * as Form from '@utima/ui-informed';
import { jwtDecode, type JwtPayload } from 'jwt-decode';
import { useTranslation } from 'react-i18next';

import { ConfirmPasswordInputs } from '@/components/confirmPasswordInputs/ConfirmPasswordInputs';
import { trpc } from '@/services/trpc';

import { VerificationInvalidTokenPage } from './VerificationInvalidTokenPage';

type VerificationPageProps = {
  token: string;
};

export function VerificationPage({ token }: VerificationPageProps) {
  const { t } = useTranslation(['common', 'auth']);
  const verifyMutation = trpc.user.verify.useMutation();
  const navigate = useNavigate();

  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
  };

  let exp: number = 0;

  try {
    const jwtPayload = jwtDecode<JwtPayload & { email: string }>(token);
    initialValues.email = jwtPayload.email;
    exp = jwtPayload.exp!;
  } catch (e) {
    console.error(e);
  }

  if (!token || !initialValues.email || exp < Date.now() / 1000) {
    return <VerificationInvalidTokenPage />;
  }

  const onSubmit = (formState: Form.TypedFormState<typeof initialValues>) => {
    verifyMutation.mutate(
      {
        token: token,
        password: formState.values.password,
      },
      {
        onError: error => {
          toast.error(t('common:toast.error'), {
            description: error.message,
          });
        },
        onSuccess: _data => {
          toast.success(t('common:toast.success'), {
            description: t('auth:verification.success'),
          });
          navigate({ to: '/login' });
        },
      },
    );
  };

  return (
    <Form.Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      className='flex w-full flex-col gap-6'
    >
      <h1 className='text-center text-2xl font-bold'>
        {t('auth:verification.title')}
      </h1>
      <Form.Input
        name='email'
        type='email'
        label={t('auth:input.email')}
        placeholder={t('auth:input.email')}
        disabled
        required
      />
      <ConfirmPasswordInputs />
      <Button variant='primary' size='lg' type='submit'>
        {t('auth:verification.button')}
      </Button>
    </Form.Form>
  );
}
