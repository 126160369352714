import { useTranslation } from 'react-i18next';

import {
  BreadcrumbComponent,
  type BreadcrumbItem,
} from '@/components/breadCrumb/BreadcrumbComponent';
import { PageTitle } from '@/components/pageTitle/PageTitle';
import { feedbackRoute } from '@/routes/feedbackRoute';

import { FeedbackTabs } from './FeedbackTabs';
import { UserFeedBacksTable } from './userFeedbackTable/userFeedbackTable';

export function ChatBotManagementFeedbackPage() {
  const { t } = useTranslation(['feedbacks', 'common']);
  const { userId } = feedbackRoute.useParams();
  const managementFeedbacksLinks: BreadcrumbItem[] = [
    { label: t('common:titles.messageManagement'), href: '/messages' },
    { label: t('common:titles.threads'), href: `/messages/threads/${userId}` },
    {
      label: t('common:titles.feedback'),
      href: `/messages/threads/${userId}/feedback`,
    },
  ];

  return (
    <div className='flex flex-col gap-3 '>
      <BreadcrumbComponent items={managementFeedbacksLinks} />

      <FeedbackTabs userId={userId} />
      <PageTitle title={t('common:titles.feedback')} />
      <UserFeedBacksTable />
    </div>
  );
}
