import { Navigate, useNavigate } from '@tanstack/react-router';
import { toast } from '@utima/ui';
import * as Form from '@utima/ui-informed';
import { useTranslation } from 'react-i18next';

import { ConfirmPasswordInputs } from '@/components/confirmPasswordInputs/ConfirmPasswordInputs';
import { FormActions } from '@/components/formActions/FormActions';
import { PageTitle } from '@/components/pageTitle/PageTitle';
import { HOME_PAGE } from '@/constants/constants';
import { trpc } from '@/services/trpc';
import { useMyStore } from '@/store/useMyStore';

const initialValues = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
};

export function ChangePasswordPage() {
  const { t } = useTranslation(['common', 'auth']);
  const user = useMyStore(state => state.user);
  const navigate = useNavigate();

  if (!user) {
    return <Navigate to='/login' replace />;
  }

  const changePasswordMutation = trpc.user.changeUserPassword.useMutation({
    onSuccess: () => {
      toast.success(t('common:toast.success'), {
        description: t('auth:resetPassword.success'),
      });
      navigate({ to: HOME_PAGE });
    },
    onError: error => {
      toast.error(t('common:toast.error'), {
        description: error.message,
      });
    },
  });

  const onSubmit = (formState: Form.TypedFormState<typeof initialValues>) => {
    changePasswordMutation.mutate({
      email: user.email,
      currentPassword: formState.values.currentPassword,
      newPassword: formState.values.password,
    });
  };

  return (
    <div className='flex flex-col gap-3'>
      <PageTitle title={t('common:titles.changePassword')} />
      <div className='flex w-full max-w-[760px] rounded-3xl bg-popover p-8'>
        <Form.Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          className='flex w-full flex-col gap-6'
        >
          <Form.Input
            name='currentPassword'
            type='password'
            label={t('auth:input.currentPassword')}
            placeholder={t('auth:input.currentPassword')}
            required
          />
          <ConfirmPasswordInputs
            firstPasswordLabel={t('auth:input.newPassword')}
          />
          <FormActions
            onCancel={() => navigate({ to: HOME_PAGE })}
            confirmText={t('auth:resetPassword.button')}
          />
        </Form.Form>
      </div>
    </div>
  );
}
