import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import './overrides';
import { App } from './App';
import { AppSettings } from './constants/AppSettings';

const dsn = AppSettings.sentry.dns;

Sentry.init({
  dsn: dsn,
  integrations: [],
});

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
