import { Link, useNavigate } from '@tanstack/react-router';
import type { Table } from '@tanstack/react-table';
import { Pagination as PaginationUI, Select, SelectItem } from '@utima/ui';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { usePagination } from './usePagination';

export function Pagination<TData>({ table }: { table: Table<TData> }) {
  const navigate = useNavigate();
  const currentPath = window.location.pathname as any;
  const { t } = useTranslation('common');
  const { pageSize, pageIndex } = table.getState().pagination;
  const { pagination, hasNext, hasPrevious } = usePagination(
    pageIndex,
    table.getPageCount(),
  );

  return (
    <div className='mx-4 my-2 flex flex-col items-end justify-between gap-4 sm:flex-row sm:items-center'>
      <PaginationUI.Root size='sm' className='mx-0 w-auto'>
        <PaginationUI.Content className='flex-wrap'>
          <PaginationUI.Item>
            <PaginationUI.Link disabled={!hasPrevious} asChild>
              <Link
                disabled={!hasPrevious}
                className='flex items-center gap-1'
                to={currentPath}
                preload={false}
                search={{
                  page: pageIndex,
                  pageSize,
                }}
              >
                <ChevronLeft className='size-4' />
                {t('dataTable.previous')}
              </Link>
            </PaginationUI.Link>
          </PaginationUI.Item>
          {pagination.map((page, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <PaginationUI.Item key={`${page}${index}`}>
              {page === '...' ? (
                <PaginationUI.Ellipsis />
              ) : (
                <PaginationUI.Link active={page === pageIndex + 1} asChild>
                  <Link
                    to={currentPath}
                    preload={false}
                    search={{
                      page: page,
                      pageSize,
                    }}
                  >
                    <div>{page}</div>
                  </Link>
                </PaginationUI.Link>
              )}
            </PaginationUI.Item>
          ))}
          <PaginationUI.Item>
            <PaginationUI.Link disabled={!hasNext} asChild>
              <Link
                disabled={!hasNext}
                className='flex items-center gap-1'
                to={currentPath}
                preload={false}
                search={{
                  page: pageIndex + 2,
                  pageSize,
                }}
              >
                {t('dataTable.next')}
                <ChevronRight className='size-4' />
              </Link>
            </PaginationUI.Link>
          </PaginationUI.Item>
        </PaginationUI.Content>
      </PaginationUI.Root>

      <div className='w-32'>
        <Select
          value={pageSize.toString()}
          onValueChange={value => {
            navigate({
              to: currentPath,
              search: {
                page: 1,
                pageSize: Number(value),
              },
            });
          }}
        >
          {[10, 25, 50].map(pageSize => (
            <SelectItem key={pageSize} value={pageSize.toString()}>
              {pageSize} {t('dataTable.items')}
            </SelectItem>
          ))}
        </Select>
      </div>
    </div>
  );
}
