import { DataTable } from '@/components/dataTable/DataTable';
import { trpc } from '@/services/trpc';
import type { PaginationParams } from '@/types';

import { useUserCols } from '../hooks/useUserCols';

export function UsersTable() {
  const columns = useUserCols();

  const useQuery = ({ pageSize, pageIndex }: PaginationParams) =>
    trpc.user.paginatedUsers.useQuery({
      pageSize: pageSize,
      pageIndex: pageIndex,
    });

  return <DataTable columns={columns} useQuery={useQuery} />;
}
