import { Button } from '@utima/ui';
import { CirclePlus } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  BreadcrumbComponent,
  type BreadcrumbItem,
} from '@/components/breadCrumb/BreadcrumbComponent';
import { PageTitle } from '@/components/pageTitle/PageTitle';

import { useCreateUserDialog } from './hooks/useCreateUserDialog';
import { UsersTable } from './usersTable/UsersTable';

export function UsersPage() {
  const { t } = useTranslation(['users', 'common']);

  const { openDialog: handleAddUserClick } = useCreateUserDialog();
  const userLinks: BreadcrumbItem[] = [
    { label: t('common:titles.users'), href: '/users' },
  ];

  return (
    <div className='flex  flex-col gap-3   '>
      <BreadcrumbComponent items={userLinks} />

      <PageTitle title={t('common:titles.users')} />
      <div className='flex justify-end'>
        <Button
          icon={<CirclePlus className='size-4' />}
          size='sm'
          onClick={handleAddUserClick}
        >
          {t('users:addUser')}
        </Button>
      </div>
      <UsersTable />
    </div>
  );
}
