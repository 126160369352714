import { createRouter } from '@tanstack/react-router';

import { adminLayoutRoute } from './adminLayoutRoute';
import { authLayoutRoute } from './authLayoutRoute';
import { faqsRoute } from './faqsRoute';
import { feedbackRoute } from './feedbackRoute';
import {
  forgottenPasswordRoot,
  forgottenPasswordRoute,
} from './forgottenPasswordRoute';
import { forgottenPasswordSentRoute } from './forgottenPasswordSentRoute';
import { loginRoute } from './loginRoute';
import { messagesRoute } from './messagesRoute';
import { operatorRoute } from './operatorsRoute';
import { changePasswordRoute } from './profilePasswordRoute';
import { profileRoute } from './profileRoute';
import { registrationsRoute } from './registrationsRoute';
import { resetPasswordRoute } from './resetPasswordRoute';
import { rootRoute } from './rootRoute';
import { threadsRoute } from './threadsRoute';
import { trpcRoot } from './trpcRoute';
import { usersRoute } from './usersRoute';
import { verifyRoute } from './verifyRoute';

const routeTree = rootRoute.addChildren([
  authLayoutRoute,
  loginRoute,
  forgottenPasswordRoot.addChildren([
    forgottenPasswordSentRoute,
    forgottenPasswordRoute,
  ]),
  resetPasswordRoute,
  verifyRoute,
  adminLayoutRoute,
  profileRoute,
  changePasswordRoute,
  trpcRoot,
  usersRoute,
  faqsRoute,
  messagesRoute,
  operatorRoute,
  registrationsRoute,
  threadsRoute,
  feedbackRoute,
]);

export const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  defaultStaleTime: 5000,
});
