import {
  findUserByEmailSchema,
  type UserFindByEmailDTO,
} from '@form-factory-ai/admin-api/src/schema/schema';
import { Link, useNavigate } from '@tanstack/react-router';
import { Button, toast } from '@utima/ui';
import * as Form from '@utima/ui-informed';
import { useTranslation } from 'react-i18next';

import { trpc } from '@/services/trpc';

const initialValues: UserFindByEmailDTO = {
  email: '',
};

export function ForgottenPasswordPage() {
  const { t } = useTranslation(['common', 'auth']);
  const navigate = useNavigate();
  const forgottenPasswordMutation = trpc.auth.forgottenPassword.useMutation();

  const onSubmit = (formState: Form.TypedFormState<UserFindByEmailDTO>) => {
    forgottenPasswordMutation.mutate(formState.values, {
      onError: error => {
        toast.error(t('common:toast.error'), {
          description: error.message,
        });
      },
      onSuccess: _data => {
        navigate({
          to: '/forgotten-password/sent',
          search: { email: formState.values.email },
        });
      },
    });
  };

  return (
    <Form.Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      zodSchema={findUserByEmailSchema}
      className='flex w-full flex-col gap-6'
    >
      <h1 className='text-center text-2xl font-bold'>
        {t('auth:forgottenPassword.title')}
      </h1>
      <div className='text-center text-sm'>
        {t('auth:forgottenPassword.subtitle')}
      </div>
      <Form.Input
        name='email'
        type='email'
        label={t('auth:input.email')}
        placeholder={t('auth:input.email')}
        required
      />
      <div className='flex gap-4'>
        <Link to='/login' className='w-full'>
          <Button variant='secondary'>
            {t('auth:forgottenPassword.toLogin')}
          </Button>
        </Link>
        <Button variant='primary' type='submit'>
          {t('auth:forgottenPassword.button')}
        </Button>
      </div>
    </Form.Form>
  );
}
