import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionCellThreads } from '../userThreadsTable/ActionCell';

export interface ThreadsType {
  createdAt: string;
  userId: string;
  threadId: string;
  title: string;
  conversationCount: number;
}

const columnHelper = createColumnHelper<ThreadsType>();

export function useThreadsCol() {
  const { t } = useTranslation('threads');

  return useMemo(
    () => [
      columnHelper.accessor('createdAt', {
        header: t('tableHead.createAt') + ' (UTC)',
      }),
      columnHelper.accessor('userId', {
        header: t('tableHead.userId'),
      }),
      columnHelper.accessor('conversationCount', {
        header: t('tableHead.numberOfMessages'),
      }),
      columnHelper.accessor('title', {
        header: t('tableHead.title'),
      }),
      columnHelper.display({
        id: 'actions',
        header: t('tableHead.actions'),
        cell: ({ row }) => <ActionCellThreads threadObject={row.original} />,
        meta: {
          className: 'w-90 text-center',
        },
      }),
    ],
    [t],
  );
}
