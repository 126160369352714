import { createRoute } from '@tanstack/react-router';

import { ChatBotManagementPage } from '@/pages/chatbotManagement/ChatbotManagementPage';

import { adminLayoutRoute } from './adminLayoutRoute';

export const messagesRoute = createRoute({
  getParentRoute: () => adminLayoutRoute,
  path: '/messages',
  component: ChatBotManagementPage,
});
