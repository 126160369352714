import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import type z from 'zod';

import { refreshTokenTable } from '../schema';

const selectRefreshTokenSchema = createSelectSchema(refreshTokenTable, {});

const insertRefreshTokenSchema = createInsertSchema(refreshTokenTable, {}).omit(
  {
    created_at: true,
  },
);

const _createRefreshTokenSchema = insertRefreshTokenSchema;

const _findRefreshTokenByUserIdSchema = selectRefreshTokenSchema.pick({
  user_id: true,
});

const _findRefreshTokenByTokenSchema = selectRefreshTokenSchema.pick({
  token: true,
});

export type RefreshTokenCreateDTO = z.infer<typeof _createRefreshTokenSchema>;
export type RefreshTokenFindByUserIdDTO = z.infer<
  typeof _findRefreshTokenByUserIdSchema
>;
export type RefreshTokenFindByTokenDTO = z.infer<
  typeof _findRefreshTokenByTokenSchema
>;
