import { createRoute } from '@tanstack/react-router';

import { ForgottenPasswordPage } from '@/pages/forgottenPassword/ForgottenPasswordPage.tsx';

import { authLayoutRoute } from './authLayoutRoute.tsx';

export const forgottenPasswordRoot = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: 'forgotten-password',
});

export const forgottenPasswordRoute = createRoute({
  getParentRoute: () => forgottenPasswordRoot,
  path: '/',
  component: ForgottenPasswordPage,
});
