import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DialogContext,
  type DialogProps,
} from '@/components/dialogContext/DialogContext';
import { useDialog } from '@/components/dialogContext/useDialog';

import { OperatorForm } from '../operatorForm/OperatorForm';

export function useCreateOperatorDialog() {
  const { t } = useTranslation('operators');

  const { setOpen } = useContext(DialogContext);
  const closeDialog = () => setOpen(false);

  const addUserDialogProps = {
    title: t('addOperator'),
    separatorClassName: 'w-24',
    contentClassName: 'max-w-xl',
    children: <OperatorForm onCancel={closeDialog} onSubmit={closeDialog} />,
  } as DialogProps;
  const { openDialog } = useDialog(addUserDialogProps);

  return { openDialog };
}
