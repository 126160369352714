import type { UserWithRelations } from '@form-factory-ai/admin-api/src/schema/schema';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionCell } from '../usersTable/ActionCell';
import { RoleBadge } from '../usersTable/RoleBadge';
import { StatusTag } from '../usersTable/StatusTag';

const columnHelper = createColumnHelper<UserWithRelations>();

export function useUserCols() {
  const { t } = useTranslation('users');

  return useMemo(
    () => [
      columnHelper.accessor(row => `${row.name} ${row.surname}`, {
        id: 'name',
        header: t('tableHead.name'),
      }),
      columnHelper.accessor(row => row.email, {
        id: 'email',
        header: t('tableHead.email'),
      }),
      columnHelper.accessor(row => row.role!.name, {
        id: 'role',
        header: t('tableHead.role'),
        cell: ({ getValue }) => <RoleBadge role={getValue()} />,
        meta: {
          className: 'text-center',
        },
      }),
      columnHelper.accessor(row => row.is_verified, {
        id: 'status',
        header: t('tableHead.status'),
        cell: ({ getValue }) => <StatusTag status={getValue()} />,
        meta: {
          className: 'text-center',
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: t('tableHead.actions'),
        cell: ({ row }) => <ActionCell userData={row.original} />,
        meta: {
          className: 'w-52 text-center',
        },
      }),
    ],
    [t],
  );
}
