import { Outlet } from '@tanstack/react-router';
import { Layout } from '@utima/ui';
import { CircleHelp } from 'lucide-react';

import { APP_VERSION } from '@/constants/constants';

import { SideBarItems } from './SideBarItems';
import TopBar from '../topBar/TopBar';

export function AdminLayout() {
  return (
    <Layout.Root hasHeader hasSidebar>
      <Layout.Header>
        <TopBar />
      </Layout.Header>
      <Layout.Sidebar className='flex flex-col justify-between bg-black p-6 text-gray-50'>
        <SideBarItems />
        <div className='flex items-center justify-between'>
          <p className='py-1 text-left text-xl'>v{APP_VERSION}</p>
          <CircleHelp className='size-6' />
        </div>
      </Layout.Sidebar>
      <Layout.Content className='flex h-[calc(100vh-64px)] flex-col bg-gray-50 p-16'>
        <Outlet />
      </Layout.Content>
    </Layout.Root>
  );
}
