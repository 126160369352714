import {
  CircleHelp,
  MailCheck,
  Settings,
  UserRound,
  BriefcaseBusiness,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';

import SideBarItem from '../sideBarItem/SideBarItem';

export function SideBarItems() {
  const { t } = useTranslation('common');

  return (
    <div className='flex flex-col gap-4'>
      <SideBarItem
        icon={<UserRound className='size-6' />}
        title={t('titles.users')}
        url='/users'
      />
      <SideBarItem
        icon={<CircleHelp className='size-6' />}
        title={t('titles.faqs')}
        url='/faqs'
      />
      <SideBarItem
        icon={<MailCheck className='size-6' />}
        title={t('titles.messages')}
        url='/messages'
      />
      <SideBarItem
        icon={<BriefcaseBusiness className='size-6' />}
        title={t('titles.registration')}
        url='/registrationList'
      />
      <SideBarItem
        icon={<Settings className='size-6' />}
        title={t('titles.operators')}
        url='/operators'
      />
    </div>
  );
}
