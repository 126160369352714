import { Button } from '@utima/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trpc } from '@/services/trpc';
import { useMyStore } from '@/store/useMyStore';

import type { ThreadsType } from '../hooks/useThreadsCol';

export function ActionCellThreads({
  threadObject,
}: {
  threadObject: ThreadsType;
}) {
  const openBar = useMyStore(state => state.setOpenThreadsBar);
  const setItems = useMyStore(state => state.setItems);
  const { t } = useTranslation('threads');
  const [enable, setEnable] = useState(false);

  const userMessages = trpc.aiCoreRouter.getUserMessages.useQuery(
    {
      threadId: threadObject.threadId,
    },
    { enabled: enable },
  );

  useEffect(() => {
    if (userMessages.data?.items && userMessages.data.items.length > 0) {
      setItems(userMessages.data.items);
    }
  }, [setItems, userMessages.data]);

  const handleClick = async () => {
    setEnable(true);
    openBar();
  };

  return (
    <div className='flex justify-center'>
      <Button size='sm' className='m-1' outline onClick={handleClick}>
        {t('buttons.showMessages')}
      </Button>
    </div>
  );
}
