import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useFormTranslations() {
  const { t } = useTranslation('form');

  return useMemo(() => {
    return {
      actions: {
        success: {
          title: t('actions.success.title'),
          message: t('actions.success.message'),
        },
        fail: {
          title: t('actions.fail.title'),
          message: t('actions.fail.message'),
        },
      },
      errors: {
        required: t('errors.required'),
      },
      labels: {
        optional: t('labels.optional'),
      },
    };
  }, [t]);
}
