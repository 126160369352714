import type { StateCreator } from 'zustand';

type ItemsType = {
  conversationData: {
    id: number;
    name: string;
    role?: string;
    content?: string;
  }[];
}[];

export type ControlSlice = {
  threadsBar: boolean;
  setOpenThreadsBar: () => void;
  items: ItemsType;
  setItems: (items: ItemsType) => void;
};

export const controlSlice: StateCreator<ControlSlice> = set => ({
  threadsBar: false,
  setOpenThreadsBar: () => set(state => ({ threadsBar: !state.threadsBar })),
  items: [], // Initialize items as an empty array
  setItems(items) {
    set({ items });
  },
});
