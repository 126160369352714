import { Button } from '@utima/ui';
import { CirclePlus } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  BreadcrumbComponent,
  type BreadcrumbItem,
} from '@/components/breadCrumb/BreadcrumbComponent';
import { PageTitle } from '@/components/pageTitle/PageTitle';

import { useCreateOperatorDialog } from './hooks/useCreateOperatorDialog';
import { OperatorsTable } from './operatorsTable/OperatorsTable';

export function OperatorsPage() {
  const { t } = useTranslation(['operators', 'common']);

  const { openDialog: handleAddOperatorClick } = useCreateOperatorDialog();
  const operatorsLinks: BreadcrumbItem[] = [
    { label: t('common:titles.operators'), href: '/operators' },
  ];

  return (
    <div className='flex  flex-col gap-3   '>
      <BreadcrumbComponent items={operatorsLinks} />

      <PageTitle title={t('common:titles.operators')} />
      <div className='flex justify-end'>
        <Button
          icon={<CirclePlus className='size-4' />}
          size='sm'
          onClick={handleAddOperatorClick}
        >
          {t('operators:addOperator')}
        </Button>
      </div>
      <OperatorsTable />
    </div>
  );
}
