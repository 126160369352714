import { Select } from '@utima/ui-informed';
import { useFieldState, useFormApi } from 'informed';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function OperatorBranchSelect() {
  const { t } = useTranslation('operators');

  const selectedCity = useFieldState('city');
  const formApi = useFormApi();

  const filteredBranches = useMemo(() => {
    const branches = [
      { id: 1, city: 'Praha', name: 'Anděl' },
      { id: 2, city: 'Praha', name: 'Butovice' },
      { id: 3, city: 'Praha', name: 'Černý Most' },
      { id: 4, city: 'Praha', name: 'Eden' },
      { id: 5, city: 'Praha', name: 'Harfa' },
      { id: 6, city: 'Praha', name: 'Hostivař' },
      { id: 1, city: 'Praha', name: 'Karlín' },
      { id: 2, city: 'Praha', name: 'Ládví' },
      { id: 3, city: 'Praha', name: 'Letňany' },
      { id: 4, city: 'Praha', name: 'Palladium' },
      { id: 5, city: 'Praha', name: 'Pankrác' },
      { id: 6, city: 'Praha', name: 'SO-HO' },
      { id: 1, city: 'Praha', name: 'Stodůlky' },
      { id: 2, city: 'Praha', name: 'Václavské Náměstí' },
      { id: 3, city: 'Praha', name: 'Vinohradská' },
      { id: 4, city: 'Praha', name: 'Vršovická' },
      { id: 5, city: 'Brno', name: 'Lužánky' },
      { id: 6, city: 'Brno', name: 'Vlňěná' },
      { id: 6, city: 'Ostrava', name: 'Ostrava Avion' },
    ];

    return branches.filter(branch => branch.city === selectedCity.value);
  }, [selectedCity.value]);

  useEffect(() => {
    formApi.setValue('branch', '');
  }, [selectedCity.value, formApi]);

  return (
    <Select
      name='branch'
      label={t('form.branch')}
      placeholder={t('form.branch')}
      disabled={!selectedCity.value}
      required
    >
      {filteredBranches.map(branch => (
        <Select.Item key={branch.id} value={branch.name}>
          {branch.name}
        </Select.Item>
      ))}
    </Select>
  );
}
