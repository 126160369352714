import { createRoute } from '@tanstack/react-router';

import { ChatBotManagementThreadsPage } from '@/pages/chatbotManagement/ChatbotManagementThreadsPage';

import { adminLayoutRoute } from './adminLayoutRoute';

export const threadsRoute = createRoute({
  getParentRoute: () => adminLayoutRoute,
  path: '/messages/threads/$userId',
  component: ChatBotManagementThreadsPage,
});
