import { useTranslation } from 'react-i18next';

import {
  BreadcrumbComponent,
  type BreadcrumbItem,
} from '@/components/breadCrumb/BreadcrumbComponent';
import { PageTitle } from '@/components/pageTitle/PageTitle';

import { RegistrationsTable } from './registrationsTable/RegistrationsTable';

export function RegistrationsPage() {
  const { t } = useTranslation();
  const registartionsLinks: BreadcrumbItem[] = [
    { label: t('titles.registration'), href: '/registrationList' },
  ];

  return (
    <div className='flex flex-col gap-3   '>
      <BreadcrumbComponent items={registartionsLinks} />

      <PageTitle title={t('titles.registration')} />
      <RegistrationsTable />
    </div>
  );
}
